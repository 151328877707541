import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

type NavigationAwayDialogPropsType = {
    isOpen: boolean,
    handleClose: (event: React.MouseEvent) => void,
    handleLogout: () => void,
    children?: React.ReactNode;
};

const NavigationAwayDialog: React.FC<NavigationAwayDialogPropsType> = (props) => {
    const { t } = useTranslation();
    const title = t("navigation_away_dialog_message");
    const content = t("navigation_away_dialog_paragraph");
    const leaveButton = t("navigation_away_dialog_button_leave");
    const stayButton = t("navigation_away_dialog_button_stay");
    const stay = (event: React.MouseEvent) => {
        props.handleClose(event);
      }
    
    const leave = (event: React.MouseEvent) => {
        props.handleClose(event);
        props.handleLogout();
    }

    return (
        <Dialog
                open={props.isOpen}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="navagation_away_heading">
                <Typography  variant="h3" align="center" variantMapping={{ h3: 'span' }}>{title}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography
                        component={"span"} align="center" id="navigation-away_text" style={{ margin: '0', fontSize: '16px', textAlign: 'center' }}
                    >{content}
                    </Typography>
                </DialogContentText>    
            </DialogContent>
            <DialogActions>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
                    <button className="btn-wsib" type="submit" id="leave_button"
                    onClick={leave}
                    style={{ fontSize: '16px', textAlign: 'center', lineHeight: '1.42857143' }}>{leaveButton}</button>
                    <div id="stay_button" style={{ margin: '24px auto 40px auto' }}><a href="#javascript" onClick={stay}>{stayButton}</a></div>
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default NavigationAwayDialog;
