import React, { createContext, useState } from "react";
import { Forms } from '../models/Forms';
import FileItem from '../models/FileItem';
import { EdobForms } from "../models/EdobForms";
import { Categories } from "../models/Categories";

// the state maintained in stateProvider component are only updated when a step is submitted. So the context will be updated and 
// all sub components will get the update. The state provider componnet is also used to provide the initial state value whne a 
// sub step component is created.

type StateContextType = {
    formList: Forms[],
    setFormList: (formList: Forms[]) => void,
    firstnameClaimant: string,
    setfirstnameClaimant: React.Dispatch<React.SetStateAction<string>>,
    lastnameClaimant: string,
    setlastnameClaimant: React.Dispatch<React.SetStateAction<string>>,
    birthdayClaimant: Date | null,
    setbirthdayClaimant: React.Dispatch<Date | null>,
    accidentDateClaimant: Date | null,
    setaccidentDateClaimant: React.Dispatch<Date | null>,
    noClaimNumberCheckedClaimant: boolean,
    setnoClaimNumberCheckedClaimant: React.Dispatch<React.SetStateAction<boolean>>,
    documentUploadQueue: FileItem[],
    setDocumentUploadQueue: React.Dispatch<React.SetStateAction<FileItem[]>>,
    hasSupportDoc: string,
    setHasSupportDoc: React.Dispatch<React.SetStateAction<string>>,
    supportDocumentUploadQueue: FileItem[],
    setSupportDocumentUploadQueue: React.Dispatch<React.SetStateAction<FileItem[]>>,
    sendConfirmationEmail: boolean,
    setSendConfirmationEmail: React.Dispatch<React.SetStateAction<boolean>>,
    acceptTermOfUse: boolean,
    setAcceptTermOfUse: React.Dispatch<React.SetStateAction<boolean>>,
    uploadSubmitTime: Date | null,
    setUploadSubmitTime: React.Dispatch<Date>,
    isUncd: boolean,
    setIsUncd: React.Dispatch<React.SetStateAction<boolean>>,
    claimnumberClaimant: string,
    setClaimnumberClaimant: React.Dispatch<React.SetStateAction<string>>,
    wsibFormList: EdobForms[],
    setWsibFormList: (wsibFormList: EdobForms[]) => void,
    categoryList: Categories[],
    setCategoryList: (categoryList: Categories[]) => void,
    isWsib: boolean,
    setIsWsib: React.Dispatch<React.SetStateAction<boolean>>,
    prevClaimnumberClaimant: string,
    setPrevClaimnumberClaimant: React.Dispatch<React.SetStateAction<string>>,
    seletedAccidentDate: string,
    setSeletedAccidentDate: React.Dispatch<React.SetStateAction<string>>
    seletedAccidentMonth: string,
    setSeletedAccidentMonth: React.Dispatch<React.SetStateAction<string>>
    seletedAccidentYear: string,
    setSeletedAccidentYear: React.Dispatch<React.SetStateAction<string>>
    seletedBirthDate: string,
    setSeletedBirthDate: React.Dispatch<React.SetStateAction<string>>
    seletedBirthMonth: string,
    setSeletedBirthMonth: React.Dispatch<React.SetStateAction<string>>
    seletedBirthYear: string,
    setSeletedBirthYear: React.Dispatch<React.SetStateAction<string>>,
    seletedAccidentMonthNumStr: string,
    setSeletedAccidentMonthNumStr: React.Dispatch<React.SetStateAction<string>>,
    seletedBirthMonthNumStr: string,
    setSeletedBirthMonthNumStr: React.Dispatch<React.SetStateAction<string>>
    showAlertMsg: boolean,
    setShowAlertMsg: React.Dispatch<React.SetStateAction<boolean>>,
};

export const UncdContext = createContext<StateContextType>({
    formList: [],
    setFormList: ()=> {},
    firstnameClaimant: "",
    setfirstnameClaimant: () =>{},
    lastnameClaimant: "",
    setlastnameClaimant:() =>{}, 
    birthdayClaimant: null,
    setbirthdayClaimant: () =>{},
    accidentDateClaimant: null,
    setaccidentDateClaimant: () =>{},
    noClaimNumberCheckedClaimant: false,
    setnoClaimNumberCheckedClaimant: () =>{},
    documentUploadQueue:[],
    setDocumentUploadQueue: ()=>{},
    hasSupportDoc: "",
    setHasSupportDoc: ()=>{},
    supportDocumentUploadQueue:[],
    setSupportDocumentUploadQueue: ()=>{},
    sendConfirmationEmail: false,
    setSendConfirmationEmail: ()=>{},
    acceptTermOfUse: false,
    setAcceptTermOfUse: () => {},
    uploadSubmitTime: null,
    setUploadSubmitTime: () => {},
    isUncd: false,
    setIsUncd: () => {},
    claimnumberClaimant: "",
    setClaimnumberClaimant: () => {},
    wsibFormList: [],
    setWsibFormList: ()=> {},
    categoryList: [],
    setCategoryList: ()=> {},
    isWsib: true,
    setIsWsib: () => {},
    prevClaimnumberClaimant: "",
    setPrevClaimnumberClaimant: () => {},
    seletedBirthDate: "",
    setSeletedBirthDate: () => {},
    seletedBirthMonth: "",
    setSeletedBirthMonth: () => {},
    seletedBirthYear: "",
    setSeletedBirthYear: () => {},
    seletedAccidentDate: "",
    setSeletedAccidentDate: () => {},
    seletedAccidentMonth: "",
    setSeletedAccidentMonth: () => {},
    seletedAccidentYear: "",
    setSeletedAccidentYear: () => {},
    seletedAccidentMonthNumStr: "",
    setSeletedAccidentMonthNumStr: () => {},
    seletedBirthMonthNumStr: "",
    setSeletedBirthMonthNumStr: () => {},
    showAlertMsg: false,
    setShowAlertMsg: () => {}
});

interface StateProviderProps {
    children: React.ReactNode;
}
export const StateProvider: React.FC<StateProviderProps> = ({ children }) => {

    // Currently claimant info data are maintained in both Claimant component and data provider component, the data provider component is
    // update when user clicks submit button in claimant step. The data stored in data provider component is used to initialize the claimant
    // component's value. 
    // TODO: remove data mananged in Claimant component, and only maintain the data in state provider component. Verify the change will not cause
    // any performance issue.
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [birthday, setBirthday] = useState<Date | null>(null);
    const [accidentDate, setAccidentDate] = useState<Date | null>(null);
    const [noClaimNumberChecked, setNoClaimNumberChecked] = useState<boolean>(false);
    const [forms, setForms] = useState<Forms[]>([]);

    // upload queue data are only maintained in state provider component.
    const [uploadQueue, setUploadQueue] = useState<FileItem[]>([]);
    const [supportUploadQueue, setSupportUploadQueue] = useState<FileItem[]>([]);
    const [hasSupportDoc, setHasSupportDoc] = useState<string>("");
    const [sendConfirmEmail, setSendConfirmEmail] = useState<boolean>(false);
    const [acceptTerm, setAcceptTerm] = useState<boolean>(false);
    const setFormList = (formList: Forms[]) => {
        setForms(formList);
    };
    const [submitTime, setSubmitTime] = useState<Date | null>(null);
    const [isUncd, setIsUncd] = useState<boolean>(false);
    const [claimnumber, setClaimnumber] = useState<string>("");
    const [wsibForms, setWsibForms] = useState<EdobForms[]>([]);
    const [categories, setCategories] = useState<Categories[]>([]);
    const setWsibFormList = (wsibFormList: EdobForms[]) => {
        setWsibForms(wsibFormList);
    };
    const setCategoryList = (categoryList: Categories[]) => {
        setCategories(categoryList);
    };
    const [isWsib, setIsWsib] = useState<boolean>(true);

    const [prevClaimnumberClaimant, setPrevClaimnumberClaimant] = useState<string>("");

    const [seletedBirthDate, setSeletedBirthDate] = useState<string>("");

    const [seletedBirthMonth, setSeletedBirthMonth] = useState<string>("");

    const [seletedBirthYear, setSeletedBirthYear] = useState<string>("");

    const [seletedAccidentDate, setSeletedAccidentDate] = useState<string>("");

    const [seletedAccidentMonth, setSeletedAccidentMonth] = useState<string>("");

    const [seletedAccidentYear, setSeletedAccidentYear] = useState<string>("");

    const [seletedAccidentMonthNumStr, setSeletedAccidentMonthNumStr] = useState<string>("");

    const [seletedBirthMonthNumStr, setSeletedBirthMonthNumStr] = useState<string>("");

    const [showAlertMsg, setShowAlertMsg] = useState<boolean>(false);


    const stateContext : StateContextType = {
        formList: forms,
        setFormList: setFormList,
        firstnameClaimant: firstname,
        setfirstnameClaimant: setFirstname,
        lastnameClaimant: lastname,
        setlastnameClaimant: setLastname,
        birthdayClaimant: birthday,
        setbirthdayClaimant: setBirthday,
        accidentDateClaimant: accidentDate,
        setaccidentDateClaimant: setAccidentDate,
        noClaimNumberCheckedClaimant: noClaimNumberChecked,
        setnoClaimNumberCheckedClaimant: setNoClaimNumberChecked,
        documentUploadQueue: uploadQueue,
        setDocumentUploadQueue: setUploadQueue,
        hasSupportDoc: hasSupportDoc,
        setHasSupportDoc: setHasSupportDoc,
        supportDocumentUploadQueue: supportUploadQueue,
        setSupportDocumentUploadQueue: setSupportUploadQueue,
        sendConfirmationEmail: sendConfirmEmail,
        setSendConfirmationEmail: setSendConfirmEmail,
        acceptTermOfUse: acceptTerm,
        setAcceptTermOfUse: setAcceptTerm,
        uploadSubmitTime: submitTime,
        setUploadSubmitTime: setSubmitTime,
        isUncd: isUncd,
        setIsUncd: setIsUncd,
        claimnumberClaimant: claimnumber,
        setClaimnumberClaimant: setClaimnumber,
        wsibFormList: wsibForms,
        setWsibFormList: setWsibFormList,
        categoryList: categories,
        setCategoryList: setCategoryList,
        isWsib: isWsib,
        setIsWsib: setIsWsib,
        prevClaimnumberClaimant: prevClaimnumberClaimant,
        setPrevClaimnumberClaimant: setPrevClaimnumberClaimant,
        seletedBirthDate: seletedBirthDate,
        setSeletedBirthDate: setSeletedBirthDate,
        seletedBirthMonth: seletedBirthMonth,
        setSeletedBirthMonth: setSeletedBirthMonth,
        seletedBirthYear: seletedBirthYear,
        setSeletedBirthYear: setSeletedBirthYear,
        seletedAccidentDate: seletedAccidentDate,
        setSeletedAccidentDate: setSeletedAccidentDate,
        seletedAccidentMonth: seletedAccidentMonth,
        setSeletedAccidentMonth: setSeletedAccidentMonth,
        seletedAccidentYear: seletedAccidentYear,
        setSeletedAccidentYear: setSeletedAccidentYear,
        seletedAccidentMonthNumStr: seletedAccidentMonthNumStr,
        setSeletedAccidentMonthNumStr: setSeletedAccidentMonthNumStr,
        seletedBirthMonthNumStr: seletedBirthMonthNumStr,
        setSeletedBirthMonthNumStr: setSeletedBirthMonthNumStr,
        showAlertMsg: showAlertMsg,
        setShowAlertMsg: setShowAlertMsg
    };

    return <UncdContext.Provider value={stateContext}>{children}</UncdContext.Provider>
}

//export constants
export const allowedFileTypes = ['docx', 'jpeg', 'jpg', 'tiff', 'tif', 'pdf'];