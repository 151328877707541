import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import './index.css';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./AuthConfig";

const appVersion = "0.0.79";
console.log("UNCD react app version: " + appVersion);
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */

const getMsalInstance = (configKey: string) => {
    const configuration = msalConfig[configKey];
    return new PublicClientApplication(configuration);
}

console.log(JSON.stringify(msalConfig));
const isSignUpPath = window.location.pathname.includes('/signup');
const useSignUpPolicy = isSignUpPath;

const msalInstance = getMsalInstance(useSignUpPolicy? 'signup' : 'signin');
console.log('MSAL_Instance', msalInstance);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}


msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    // Casting payload as AuthenticationResult to access account
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  } else if(event.eventType === EventType.LOGIN_FAILURE) {
    if(event.error?.message.includes("AADB2C90091")) {
        const lang = window.sessionStorage.getItem('lang')!;
        getMsalInstance('signin').loginRedirect({
            scopes: ["openid", "offline_access", process.env.REACT_APP_Scope!],
            extraQueryParameters: {ui_locales: lang!},
          });
    }
  }
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Suspense fallback={<div>loading...</div>}>
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Router>
          <App />
        </Router>
      </MsalProvider>
    </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
