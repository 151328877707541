import React, { useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

type ResetPasswordPropsType = {
    onLogout: () => void;
}
const ResetPassword: React.FC<ResetPasswordPropsType> = (props) => {
    const { t } = useTranslation();
    const logUserOutToLogIn = () => {
        props.onLogout();
    }

    useEffect(() => {
        window.history.pushState('','','/resetpassword');
        document.title = t("resetPasswordTitle");
    });

    return (
            <Container style={{ marginTop: '40px' }}>
                <Grid container role="region" aria-labelledby="region-info">
                    <Grid item xs={12} className="row"  style={{ marginBottom: '20px' }}>
                        <div><Typography variant="h1" id="region-info">{t("reset.password.success")}</Typography></div>
                    </Grid>
                    <Grid item xs={12} className="row">
                        <div><Typography variant="body1" id="region-info">{t("reset.password.youmay")}<Link tabIndex={0} className={'link'} underline="hover" onClick={logUserOutToLogIn}>{t("reset.password.login")}</Link>.</Typography></div>
                    </Grid>
                </Grid>
            </Container>
    )
}

export default ResetPassword;
