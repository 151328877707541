import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { useMsal } from "@azure/msal-react";
import { allowedFileTypes, UncdContext } from "./StateProvider"
import FileTypeInfoDialog from "../dialogs/FileTypeInfoDialog";
import UploadInProgressDialog from "../dialogs/UploadInProgressDialog";
import FileDropzone from './FileDropzone';
import FileItem, { FileItemCallback } from '../models/FileItem';
import FileUploader from '../services/FileUploader';
import { FailedFileListsType, getToken, ParsedResponseHeaders, StepComponentProps, getSupportingFormNameList } from "../Helper";
import { DropEvent, FileRejection } from 'react-dropzone';
import { Forms } from '../models/Forms';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { AnalyticsLink, LinkKey } from '../services/AnalyticsLink';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FormDropdownList from './FormDropdownList';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import { EdobForms } from '../models/EdobForms';
import { Categories } from '../models/Categories';

const SupportDocUpload: React.FC<StepComponentProps> = (props) => {
    const { instance } = useMsal();
    const ctx = useContext(UncdContext);
    const { t, i18n } = useTranslation();
    const [submitClicked, setSubmitClicked] = useState(false);
    const [failedFileLists, setFailedFileLists] = useState<FailedFileListsType>({
        serverError: false,
        fileWrongSizeList: [],
        fileWrongTypeList: [],
        filenameInvalidList: [],
        fileVirusList: [],
        maxFileNumReached: false
    });

    const fileUploader = new FileUploader(process.env.REACT_APP_uploadFileUrl!);

    const [fileTypeInfoOpen, setFileTypeInfoOpen] = useState(false);
    const [uploadInProgressOpen, setUploadInProgressOpen] = useState(false);
    const [noDocumentError, setNoDocumentError] = useState(false);
    const [resetSubForms, setResetSubForms] = useState('');
    const wsibSelectedForms = useRef('');
    const sendFlag = useRef(false);

    const analyticsLink = new AnalyticsLink();

    //google analytics
    if (!sendFlag.current) {
        analyticsLink.sendLinkAnalyticsReport(LinkKey.uploadSupport, i18n.resolvedLanguage);
        sendFlag.current = true;
    }
    const maxFiles = 5;

    useEffect(() => {
        // window.scrollTo(0, 0);
        window.history.pushState('','','/uploadsupportingdocuments');
        document.title = t("supportingDocTitle");
        let noscriptEles = document.getElementsByTagName("noscript");
        for (let i = 0; i < noscriptEles.length; i++) {
            noscriptEles[i].removeAttribute('aria-hidden');
        }
    });

    //file item handler
    const onBeforeUploadItem = async (item: FileItem) => {

        // the token set in uploader object may be expired after a while, so need to
        // refresh the token before sending the update request to server
        const token = await getToken(instance);
        if (token) {
            const newHeaders = item.headers.filter(
                (x) => x.name !== 'Authorization'
            );
            let authToken = 'Bearer ' + token;
            newHeaders.push({ name: 'Authorization', value: authToken });

            item.headers = newHeaders;
        }
    };

    const updateQueueState = () => {
        ctx.setSupportDocumentUploadQueue((oldValue) => {
            return [...oldValue];
        })
    }
    const onItemProgress = (item: FileItem, progress: number) => {
        updateQueueState();
    }

    // if server does not return json, then return -1 to indicate server error
    const getErrorCodeFromResponse = (response: string) => {
        try {
            const responseJson = JSON.parse(response);
            const errorCode = responseJson.apiErrorCode;
            if (isNaN(errorCode)) {
                return -1;
            } else {
                return errorCode;
            }
        } catch (err) {
            console.log('Error from server', err);
            return -1;
        }
    }

    const onItemComplete = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
        // result is handled by noItemSuccess and onItemError. 
    }

    const onItemSuccess = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
        if (item.isCancel) {
            return { item, response, status, headers };
        }

        try {
            const responseJson = JSON.parse(response);
            const fileId = responseJson.name;
            if (!fileId) {
                console.log("Fail to get fileID from response as json: " + response);
                onItemError(item, "Fail to get fileID from response", -1, {});
            } else {
                item.alias = fileId;
            }
        }
        catch (err) {
            //if response is not json, then report an error
            console.log("Fail to parse response as json: " + response);
            onItemError(item, "Fail to parse response as json: ", -1, {});
        }

        updateQueueState();
        return { item, response, status, headers };
    }

    const setServerError = () => {
        failedFileLists.serverError = true;
        setFailedFileLists((oldValue) => {
            return { ...oldValue, serverError: true }
        });
    }

    const onItemError = (item: FileItem, response: string, status: number, header: ParsedResponseHeaders) => {
        console.log(response);
        if (status === 400) {
            const errorCode = getErrorCodeFromResponse(response);
            if (errorCode === 202) {
                // file content type rejected by server
                removeDocItem(item, null);
                setFailedFileLists((old) => {
                    if (!old.fileWrongTypeList.includes(item._file.name)) {
                        old.fileWrongTypeList.push(item._file.name);
                        return { ...old };
                    }
                    return old;
                })
            } else {
                // google analytics
                analyticsLink.sendLinkAnalyticsReport(LinkKey.technical, i18n.resolvedLanguage);
                setServerError();
            }
        } else if (status === 403) {
            const errorCode = getErrorCodeFromResponse(response);
            // Error handler for uploader:
            if (errorCode === 501) {
                // virus found
                removeDocItem(item, null);
                setFailedFileLists((old) => {
                    if (!old.fileVirusList.includes(item._file.name)) {
                        old.fileVirusList.push(item._file.name);
                        return { ...old };
                    }
                    return old;
                })
            } else {
                // there was a technical error with virus checker (indicate a back end error)
                // google analytics
                analyticsLink.sendLinkAnalyticsReport(LinkKey.technical, i18n.resolvedLanguage);
                setServerError();

            }
        } else if (status === 404) {
            // file size over backend max limit
            removeDocItem(item, null);
            setFailedFileLists((old) => {
                if (!old.fileWrongSizeList.includes(item._file.name)) {
                    old.fileWrongSizeList.push(item._file.name);
                    return { ...old };
                }
                return old;
            })
        } else {
            // all other status codes are considered a server error scenario
            // google analytics
            analyticsLink.sendLinkAnalyticsReport(LinkKey.technical, i18n.resolvedLanguage);
            setServerError();
        }
        updateQueueState();
    }

    const onItemCancel = (item: FileItem, response: string, status: number, header: ParsedResponseHeaders) => {
        updateQueueState();
    }

    //react-dropzone maxFiles property only check file number in a single drag-drop operation, it
    //does not validate the total files in upload queue. So it cannot be used to limit the max file number.
    const onFileSelected: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void = (acceptedFiles, rejectedFiles) => {
        //first reset error of previous file selection
        clearUploaderErrors();

        acceptedFiles.forEach(element => {
            const callback: FileItemCallback = {
                onBeforeUpload: onBeforeUploadItem,
                onProgress: onItemProgress,
                onSuccess: onItemSuccess,
                onError: onItemError,
                onCancel: onItemCancel,
                onComplete: onItemComplete
            };
            const fileItem = new FileItem(element, callback);
            ctx.setSupportDocumentUploadQueue((old) => {
                if (old.length < maxFiles) {
                    return ([...old, fileItem]);
                } else {
                    setFailedFileLists((prevState: FailedFileListsType) => {
                        return { ...prevState, maxFileNumReached: true };
                    })
                    return old;
                }
            })
            fileUploader.uploadItem(fileItem);
            console.log(fileItem, fileUploader);
            console.log("file accepted: " + fileItem._file.name);
        });

        rejectedFiles.forEach(fileRejection => {
            if (fileRejection.errors[0] && fileRejection.errors[0].code === "file-too-large") {
                setFailedFileLists((old) => {
                    if (!old.fileWrongSizeList.includes(fileRejection.file.name)) {
                        old.fileWrongSizeList.push(fileRejection.file.name);
                        return { ...old };
                    }
                    return old;
                })
            } else if (fileRejection.errors[0] && fileRejection.errors[0].code === "file-invalid-type") {
                setFailedFileLists((old) => {
                    if (!old.fileWrongTypeList.includes(fileRejection.file.name)) {
                        old.fileWrongTypeList.push(fileRejection.file.name);
                        return { ...old };
                    }
                    return old;
                })
            } else if (fileRejection.errors[0] && fileRejection.errors[0].code === "file-name-invalid") {
                setFailedFileLists((old) => {
                    if (!old.filenameInvalidList.includes(fileRejection.file.name)) {
                        old.filenameInvalidList.push(fileRejection.file.name);
                        return { ...old };
                    }
                    return old;
                })
            }
            console.log("file rejected: " + fileRejection.file.name + ', ' + fileRejection.errors[0].code);
        });
    };

    // dialog handler
    const showFileFormatInfo = (event: React.MouseEvent) => {
        event.preventDefault();
        setFileTypeInfoOpen(true);
    }

    const keyDownHandler = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.code === "Enter") {
            setFileTypeInfoOpen(true);
        }
      };

    const closeFileTypeInfoOpenDialog = (event: React.MouseEvent) => {
        event.preventDefault();
        setFileTypeInfoOpen(false);
    }

    const showUploadInProcessDialog = () => {
        setUploadInProgressOpen(true);
    }

    const closeUploadInProgressDialog = (event: React.MouseEvent) => {
        event.preventDefault();
        setUploadInProgressOpen(false);
    }

    const onRemoveHandler = (fileItem: FileItem, event: React.MouseEvent) => {
        clearUploaderErrors();
        //if the last document is removed, then reset submit flag.
        if (ctx.supportDocumentUploadQueue.length === 1) {
            setSubmitClicked(false);
        }
        removeDocItem(fileItem, event);
    };

    const removeDocItem = (fileItem: FileItem, event: React.MouseEvent | null) => {
        if (event) {
            event.preventDefault();
        }

        if (fileItem.isUploading) {
            fileItem.cancel();
        }

        ctx.setSupportDocumentUploadQueue((oldQueue) => {
            const docQueue = oldQueue.filter(item => item !== fileItem);
            return docQueue;
        })
    }


    // customization to only show progress up to 99% before the uploading status changed to uploaded
    const getItemProgress = (item: FileItem) => {
        // console.log(item._file.name + " progress: " + item.progress);
        if (item.progress !== 100) {
            return item.progress;
        } else {
            if (item.isUploaded) {
                return item.progress;
            } else {
                return 99;
            }
        }
    }

    const getWsibForm = (value: string) => {
        let newForm: Forms[] = [];

        if (i18n.resolvedLanguage === 'en') {
            newForm = ctx.formList.filter((form) => {
                return value === form.formNameEn.replaceAll('’', '\'');
            });
        } else {
            newForm = ctx.formList.filter((form) => {
                return value === form.formNameFr.replaceAll('’', '\'');
            });
        }

        return newForm[0];
    }

    const formTypeChanged = (value: string, item: FileItem) => {
        wsibSelectedForms.current = value;
        const newForm = getWsibForm(value);
        item.wsibForm = newForm;

        updateQueueState();
    }

    /**
    * Clear the file error messages.
    */
    const clearUploaderErrors = () => {
        setFailedFileLists({
            serverError: false,
            fileWrongSizeList: [],
            fileWrongTypeList: [],
            filenameInvalidList: [],
            fileVirusList: [],
            maxFileNumReached: false
        });
        setNoDocumentError(false);
    }

    const onSubmit = () => {
        return new Promise (resolve => setTimeout(() => {
            setSubmitClicked(true);

            //
            if (ctx.hasSupportDoc === "") {
                window.scrollTo(0, 0);
                return;
            }
    
            if (ctx.hasSupportDoc === "yes" && ctx.supportDocumentUploadQueue.length === 0) {
                // show no document to submit error (MSG-14)
                window.scrollTo(0, 0);
                setNoDocumentError(true);
                return;
            }
    
            for (const item of ctx.supportDocumentUploadQueue) {
                if (!item.wsibForm) {
                    return;
                }
            }
    
            for (const item of ctx.supportDocumentUploadQueue) {
                // show progress dialog if document is still uploading or not yet start to upload
                if (item.isUploading || !item.isSuccess) {
                    showUploadInProcessDialog();
                    return;
                }
            }
    
            props.onValidated();
        }, 100));
    }

    const onNext = async () => {
        clearUploaderErrors();
        await onSubmit();
       
    }

    const handleHasSupportDocChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === 'no') {
            ctx.setSupportDocumentUploadQueue([]);
        }
        ctx.setHasSupportDoc((event.target as HTMLInputElement).value);
    };

    const onfileDialogOpen: () => void = () => {
        setNoDocumentError(false);
    }

    const onfileDialogCancel: () => void = () => {
        if (submitClicked && ctx.hasSupportDoc === "yes" && ctx.supportDocumentUploadQueue.length === 0) {
            setNoDocumentError(true);
        }
    }

    const getDefaultValue = (item: FileItem) => {
        if (item.wsibForm) {
            return i18n.resolvedLanguage === 'en' ? item.wsibForm.formNameEn : item.wsibForm.formNameFr;
        } else {
            return '';
        }
    }

    const switchEdobForm = (value: string) => {
        let newForm: EdobForms[] = [];

        if (i18n.resolvedLanguage === 'en') {
            newForm = ctx.wsibFormList.filter((form) => {
                return value === form.formNameFr
            });
        } else {
            newForm = ctx.wsibFormList.filter((form) => {
                return value === form.formNameEn
            });
        }

        return newForm[0];
    }

    const switchCategory = (value: string) => {
        let newCategory: Categories[] = [];

        if (i18n.resolvedLanguage === 'en') {
            newCategory = ctx.categoryList.filter((category) => {
                return value === category.categoryNameFr;
            });
        } else {
            newCategory = ctx.categoryList.filter((category) => {
                return value === category.categoryNameEn;
            });
        }

        return newCategory[0];
    }

    const switchWsibForm = (value: string) => {
        let newForm: Forms[] = [];

        if (i18n.resolvedLanguage === 'en') {
            newForm = ctx.formList.filter((form) => {
                return value === form.formNameFr.replaceAll('’', '\'');
            });
        } else {
            newForm = ctx.formList.filter((form) => {
                return value === form.formNameEn.replaceAll('’', '\'');
            });
        }

        return newForm[0];
    }

    const resetSubFormsHandler = (id: string) => {
        setResetSubForms(id);
    }

    return (
            <Container>
                <Grid container rowSpacing={1} aria-labelledby="region-support">
                    <Grid item xs={12} className="row">
                        {t('all_field_are_mandatory')}<p></p>
                    </Grid>

                    <Grid item xs={12} className="row">
                        <div><Typography variant="h1" id="region-support">{t("upload-supporting-doc") + ' (' + t('supportUploadStepLabel') + ')'}</Typography></div>
                        <br role="presentation"/>
                    </Grid>

                    {failedFileLists.serverError && <React.Fragment>
                        <Grid item xs={12} className="row mb-2" role="alert" >
                            <div><Typography variant="h3">{t('error_message_contact_wsib1')}</Typography></div>
                        </Grid>
                        <Grid item xs={12} className="row mb-4" role="alert" >
                            {t("error_message_contact_wsib2")}
                            {i18n.resolvedLanguage === 'en' && <a className="link" href="https://www.wsib.ca/en/contact-us"
                                target="_blank" rel="noopener noreferrer">
                                {t("error_message_contact_wsib3")}.</a>}
                            {i18n.resolvedLanguage === 'fr' && <a className="link" href="https://www.wsib.ca/fr/contactez-nous"
                                target="_blank" rel="noopener noreferrer">
                                {t("error_message_contact_wsib3")}.</a>}

                        </Grid>
                    </React.Fragment>}

                    {!failedFileLists.serverError &&
                        <React.Fragment>

                            {((submitClicked) && ctx.hasSupportDoc === "") &&
                                <Grid item xs={12} className="row mb-2">
                                    <Typography className="error" color="error" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error_message_supportingdoc_selection")}</Typography>

                                </Grid>
                            }

                            {(submitClicked && ctx.hasSupportDoc === "yes" && noDocumentError && failedFileLists.fileWrongSizeList.length === 0 &&
                                failedFileLists.fileWrongTypeList.length === 0 && failedFileLists.filenameInvalidList.length === 0 &&
                                failedFileLists.fileVirusList.length === 0) &&
                                <Grid item xs={12} className="row mb-2">
                                    <Typography className="error" color="error" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error_message_no_doc")}</Typography>

                                </Grid>
                            }
                            {failedFileLists.fileWrongSizeList.length > 0 &&
                                <Grid item xs={12} className="row mb-2">
                                    <Typography className="error" color="error" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error_message_file_size_too_big")}
                                        {failedFileLists.fileWrongSizeList.map((file, index) => {
                                            return (
                                                <div key={index.toString()} role="alert" className="ml-4 mt-1 doclist">
                                                    {file}
                                                </div>);
                                        })}
                                    </Typography>

                                </Grid>
                            }
                            {failedFileLists.fileWrongTypeList.length > 0 &&
                                <Grid item xs={12} className="row mb-2">
                                    <Typography className="error" color="error" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error_message_file_type_invalid")} {allowedFileTypes.join(', ')}
                                        {failedFileLists.fileWrongTypeList.map((file, index) => {
                                            return (
                                                <div key={index.toString()} role="alert" className="ml-4 mt-1 doclist">
                                                    {file}
                                                </div>);
                                        })}
                                    </Typography>

                                </Grid>
                            }
                            {failedFileLists.filenameInvalidList.length > 0 &&
                                <Grid item xs={12} className="row mb-2">
                                    <Typography className="error" color="error" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error_message_file_name_invalid")}
                                        {failedFileLists.filenameInvalidList.map((file, index) => {
                                            return (
                                                <div key={index.toString()} role="alert" className="ml-4 mt-1 doclist">
                                                    {file}
                                                </div>);
                                        })}
                                    </Typography>

                                </Grid>
                            }
                            {failedFileLists.fileVirusList.length > 0 &&
                                <Grid item xs={12} className="row mb-2">
                                    <Typography className="error" color="error" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error_message_virus_error")}
                                        {failedFileLists.fileVirusList.map((file, index) => {
                                            return (
                                                <div key={index.toString()} role="alert" className="ml-4 mt-1 doclist">
                                                    {file}
                                                </div>);
                                        })}

                                        <div>{t("error_message_virus_error2")}</div>

                                        <div>{t("error_message_virus_error3")}</div>

                                        <div>{t("error_message_virus_error4")}</div>

                                        <div>{t("error_message_virus_error5")}</div>
                                    </Typography>

                                </Grid>
                            }
                            {failedFileLists.maxFileNumReached &&
                                <Grid item xs={12} className="row mb-2">
                                    <Typography className="error" color="error" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error_message_upload_max_5_dob_files")}</Typography>
                                </Grid>
                            }

                            <Grid item xs={12} >
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" id="support-doctype-label">{t('do_you_have_supporting_doc')}</FormLabel>
                                    <RadioGroup row aria-labelledby="support-doctype-label" name="row-radio-buttons-group" value={ctx.hasSupportDoc} onChange={handleHasSupportDocChange}>
                                        <FormControlLabel value="yes" control={<Radio />} label={t('message_dob_documentation_docfromYes')} />
                                        <FormControlLabel value="no" control={<Radio />} label={t('message_dob_documentation_docfromNo')} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            {ctx.hasSupportDoc === "yes" &&
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        {t('label_upload_support_file')}
                                        <button className="link"
                                            aria-label={t('open_in_new_window')} onClick={showFileFormatInfo} onKeyPress={keyDownHandler}>
                                            <InfoOutlinedIcon onClick={showFileFormatInfo}></InfoOutlinedIcon>
                                        </button>

                                        <FileTypeInfoDialog isOpen={fileTypeInfoOpen} handleClose={closeFileTypeInfoOpenDialog} dialogContent={
                                            {
                                                title: t("accepted_file_format_title"),
                                                typeList: t("accepted_file_format_list"),
                                                closeButton: t("button_close")
                                            }
                                        }></FileTypeInfoDialog>

                                    </Grid>

                                    <FileDropzone text={t('drag_and_drop_document')} buttonText={t('drag_and_drop_or_browse2')}
                                        ondrop={onFileSelected} onfileDialogOpen={onfileDialogOpen} onfileDialogCancel={onfileDialogCancel} />
                                    {ctx.supportDocumentUploadQueue.length > 0 && <Grid item xs={12} >
                                        <Typography variant="h2">{t('tell_about_document')}</Typography>

                                    </Grid>}

                                    {ctx.supportDocumentUploadQueue.length > 0 && (ctx.supportDocumentUploadQueue.map((item, index) => {
                                        return <Grid item xs={12} className="row" key={'filename' + index}>

                                            <Grid container justifyContent="space-between" style={{ width: '95%', backgroundColor: '#FFFFFF', padding: 10, margin: 'auto' }} columns={2} rowSpacing={1}>
                                                <Grid item className="row" xs={1} style={{ minWidth: '108px', wordBreak: 'break-word', whiteSpace: 'break-spaces' }}>
                                                    {item._file.name}
                                                    {item._file.size / 1024 / 1024 >= 1 && <label style={{ marginLeft: 20 }}>{Math.round(item._file.size / 1024 / 1024)} {t("file_size_mb")}</label>}
                                                    {item?._file?.size / 1024 / 1024 < 1 && <label style={{ marginLeft: 20 }}>{Math.round(item._file?.size / 1024)} {t('file_size_kb')}</label>}
                                                </Grid>
                                                <Grid item xs="auto">
                                                    <button className="link" aria-label={'file' + (index + 1) + item._file.name + t('button_remove_file')} onClick={(e) => { onRemoveHandler(item, e); }}>{t('button_remove_file')}</button>
                                                </Grid>

                                                <Grid item xs={2} className="row mb-2">
                                                    <Typography variant="body1">{t("upload_progress")} {getItemProgress(item)}{t("progress_percent")}</Typography>
                                                    <div >
                                                        <LinearProgress aria-label={'file' + (index + 1) + " " + item._file.name + " " + t("upload")} variant="determinate" value={getItemProgress(item)} />
                                                    </div>

                                                </Grid>
                                                <Grid item xs={2} className="row mt-3">
                                                    <Typography variant="body1"><label aria-label={'file' + (index + 1) + item._file.name + t('message_dob_documentation_doctype')} style={{ marginBottom: '0px' }} id={'doctype' + index} htmlFor={'supportformselection' + index}>
                                                        {t('message_dob_documentation_doctype')}
                                                    </label></Typography>

                                                </Grid>
                                                <Grid item xs={2} className="row">
                                                    <FormDropdownList id={'supportformselection' + index} type='wsib forms' defaultValue={getDefaultValue(item)} placeholder={t("label_dob_documentation_doctype")}
                                                        options={getSupportingFormNameList(i18n.resolvedLanguage, ctx.formList, ctx.documentUploadQueue[0])} currentItem={item} onChangedForm={(value) => formTypeChanged(value, item)} wisbForms={(value) => switchWsibForm(value)}
                                                        edobForms={(value) => switchEdobForm(value)} categories={(value) => switchCategory(value)} resetSubFormsHandler={(value) => resetSubFormsHandler(value)} resetSubForms={resetSubForms} />
                                                    { (submitClicked && !item.wsibForm) && <FormHelperText id="formselection-helper-text" style={{ marginLeft: '14px', marginTop: '4px', marginBottom: "24px" }} error={true}>{t("error_message_select_doc_type")}</FormHelperText>}
                                                </Grid>
                                            </Grid>
                                        </Grid>;
                                    }))}
                                </React.Fragment>
                            }

                            <UploadInProgressDialog isOpen={uploadInProgressOpen} handleClose={closeUploadInProgressDialog} dialogContent={
                                {
                                    title: t("upload_in_progress_title"),
                                    content: t("upload_in_progress_content"),
                                    closeButton: t("button_close")
                                }
                            }></UploadInProgressDialog>


                            <Grid item xs={12} >
                                <br role="presentation"/>
                                <br role="presentation"/>
                                <span aria-hidden="true"></span>&nbsp;<button className="navbtn" onClick={props.onPrev} aria-label={t("button.previous") + t("gotoLabel") + t("step3Label")}>{t("button.previous")}</button><button className="btn-wsib" type="submit" onClick={onNext} aria-label={t("label.next") + t("gotoLabel") + t("step5Label")}>{t("label.next")}</button> &nbsp;<span aria-hidden="true"></span>
                            </Grid>

                        </React.Fragment>
                    }
                </Grid>
            </Container >
    );
}

export default SupportDocUpload;