import React, { useState, useEffect, useContext, useRef } from 'react';
import './ClaimantInfo.css';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { UncdContext } from "./StateProvider";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useMsal } from '@azure/msal-react';
import { AnalyticsLink, LinkKey } from '../services/AnalyticsLink';
import NavigationAwayDialog from '../dialogs/NavigationAwayDialog';
import { monthToNumberStrEn, monthToNumberStrFr } from '../models/data';
import CalendarDropdownList from './CalendarDropdownList';

type ClaimantInfoPropsType = {
  onPrev: () => void;
  onValidated: () => void;
  onChanged: () => void;
}
const ClaimantInfo: React.FC<ClaimantInfoPropsType> = (props) => {

  const ctx = useContext(UncdContext);
  const { instance } = useMsal();
  const [serverError, setServerError] = useState(false);
  // const [birthdayError, setBirthdayError] = useState(0);
  const birthdayError = useRef(0);
  const accidentDateError = useRef(0);
  // const [accidentDateError, setAccidentDateError] = useState(0);
  //0: no error, 1: empty, 2: format invalid
  const [firstnameError, setFirstNameError] = useState(0);
  const [lastnameError, setLastNameError] = useState(0);
  const [claimnumberError, setClaimnumberError] = useState(0);
  const { t, i18n } = useTranslation();
  const [submitClicked, setSubmitClicked] = useState(false);
  // const [accidentDateBeforeBirthdayError, setAccidentDateBeforeBirthdayError] = useState(false);
  const accidentDateBeforeBirthdayError = useRef(false);
  const analyticsLink = new AnalyticsLink();
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [claimNumberIsValid, setClaimNumberIsValid] = useState(true);
  const [isInProgress, setIsInProgress] = useState(false);
  const validationFailed = useRef(false);


  let changed = false;
  const today = new Date();
  today.setHours(23, 59, 59);

  const nameRegex = /^[a-zA-Z àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ.'-]*$/;
  const claimnumberRegex = /^\d{8}$/;
  const dateRegex = /^\d{1,2}$/;
  const yearRegex = /^\d{4}$/;
  const monthRegex = /^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$|^\d{1,2}$/;

  // when loading component, call form list request if form list is not available
  useEffect(() => {
    window.history.pushState('','','/addclaimantinformation');
    if (i18n.resolvedLanguage !== window.sessionStorage.getItem('lang')) {
      const lang = window.sessionStorage.getItem('lang')!;
      i18n.changeLanguage(lang);
    }
    
    document.title = ctx.isUncd ? t("addClaimantInfoTitle") : t("addEdobClaimantInfoTitle");
    let inputEle = document.getElementById('birthdate-select');
    inputEle?.removeAttribute('autocorrect');
    inputEle?.setAttribute('aria-haspopup', 'listbox');
    inputEle = document.getElementById('birthmonth-select');
    inputEle?.removeAttribute('autocorrect');
    inputEle?.setAttribute('aria-haspopup', 'listbox');
    inputEle = document.getElementById('birthyear-select');
    inputEle?.removeAttribute('autocorrect');
    inputEle?.setAttribute('aria-haspopup', 'listbox');

    inputEle = document.getElementById('accidentdate-select');
    inputEle?.removeAttribute('autocorrect');
    inputEle?.setAttribute('aria-haspopup', 'listbox');
    inputEle = document.getElementById('accidentmonth-select');
    inputEle?.removeAttribute('autocorrect');
    inputEle?.setAttribute('aria-haspopup', 'listbox');
    inputEle = document.getElementById('accidentyear-select');
    inputEle?.removeAttribute('autocorrect');
    inputEle?.setAttribute('aria-haspopup', 'listbox');

    if (ctx.isUncd && (ctx.formList == null || ctx.formList.length === 0)) {
      const formlistUrl = process.env.REACT_APP_formListUrl;
      fetch(formlistUrl!)
        .then(response => {
          if (response.status >= 300) {
            setServerError(true);
            return;
          }
          return response.json();
        })
        .then(data => {
          if (data) {
            ctx.setFormList(data);
          }
        })
        .catch(err => {
          console.log(err);
          // google analytics
          analyticsLink.sendLinkAnalyticsReport(LinkKey.technical, i18n.resolvedLanguage);
          setServerError(true);
        });
    }
    // window.scrollTo(0, 0);
  });

  const getFirstnameHelpText = (errorCode: number) => {
    if (errorCode === 0 || errorCode === 2) {
      return t("claimant-info-name-hint");
    }
    if (errorCode === 1) {
      return t("validation.manditory") + ": " + t("claimant-info-firstname-label");
    }
  }

  const getClaimnumberHelpText = (errorCode: number) => {
    if (errorCode === 0 || errorCode === 2) {
      return t("claimant-info-claimnumber-hint");
    }
    if (errorCode === 1) {
      return t("validation.manditory") + ": " + t("claimant-info-claimnumber-label");
    }
  }

  const checkClaimNumber = () => {
    if (ctx.claimnumberClaimant === '') {
      setClaimnumberError(1);
      return true;
    } else if (claimnumberRegex.test(ctx.claimnumberClaimant) === false) {
      setClaimnumberError(2);
      return true;
    }
    return false;
  }

  const claimnumberChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const claimNumber = event.target.value.trim();
    if (claimNumber === '') {
      setClaimnumberError(1);
    } else if (claimnumberRegex.test(claimNumber) === false) {
      setClaimnumberError(2);
    } else {
      setClaimnumberError(0);
    }
    if (claimNumber !== '' && !changed) {
      changed = true;
      props.onChanged();
    }
    ctx.setClaimnumberClaimant(claimNumber);
  }

  const checkFirstName = () => {
    if (ctx.firstnameClaimant.trim() === '') {
      setFirstNameError(1);
      return true;
    } else if (nameRegex.test(ctx.firstnameClaimant.trim()) === false) {
      setFirstNameError(2);
      return true;
    }
    return false;
  }

  const firstnameChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim() === '') {
      setFirstNameError(1);
    } else if (nameRegex.test(event.target.value.trim()) === false) {
      setFirstNameError(2);
    } else {
      setFirstNameError(0);
    }
    if (event.target.value.trim() !== '' && !changed) {
      changed = true;
      props.onChanged();
    }
    ctx.setfirstnameClaimant(event.target.value);
  }

  const getLastnameHelpText = (errorCode: number) => {
    if (errorCode === 0 || errorCode === 2) {
      return t("claimant-info-name-hint");
    }
    if (errorCode === 1) {
      return t("validation.manditory") + ": " + t("claimant-info-lastname-label");
    }
  }

  const checkLastName = () => {
    if (ctx.lastnameClaimant.trim() === '') {
      setLastNameError(1);
      return true;
    } else if (nameRegex.test(ctx.lastnameClaimant.trim()) === false) {
      setLastNameError(2);
      return true;
    }
    return false;
  }

  const lastnameChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim() === '') {
      setLastNameError(1);
    } else if (nameRegex.test(event.target.value.trim()) === false) {
      setLastNameError(2);
    } else {
      setLastNameError(0);
    }
    if (event.target.value.trim() !== '' && !changed) {
      changed = true;
      props.onChanged();
    }
    ctx.setlastnameClaimant(event.target.value);
  }

  const getBirthdayDateHelpText = (errorCode: number) => {
    if (errorCode === 0 || errorCode === 2) {
      return t('claimant-info-date-of-birth-hint');  
    }
    if (errorCode === 3) {
      return t('claimant-info-date-pattern-dob');
    }
    if (errorCode === 1) {
      return t("validation.manditory") + ": " + t("claimant-info-date-of-birth-label");
    }
  }

  const getAccidentDateHelpText = (errorCode: number) => {
    if (errorCode === 0 || errorCode === 2) {
      return t('claimant-info-date-of-birth-hint');
    }
    if (errorCode === 3) {
      return t('claimant-info-date-pattern-doa');
    }
    if (errorCode === 1) {
      return t("validation.manditory") + ": " + t("claimant-info-date-of-accident-label");
    }
  }

  const covertMonthToNumberStr = (type: string) => {
    const monthNumRegex = /^\d{1,2}$/;
    let month = "";
    const monthsObj = i18n.resolvedLanguage === 'en' ? monthToNumberStrEn : monthToNumberStrFr;
    const key = (Object.keys(monthsObj) as (keyof typeof monthsObj)[]).find((key) => {
        if (type === 'birth') {
            return key === ctx.seletedBirthMonth
        } else {
            return key === ctx.seletedAccidentMonth
        }
    });
    if (!key && type === 'birth' && monthNumRegex.test(ctx.seletedBirthMonth)) {
        month = ctx.seletedBirthMonth;
    }
    if (!key && type !== 'birth' && monthNumRegex.test(ctx.seletedAccidentMonth)) {
        month = ctx.seletedAccidentMonth;
    }
    if (month.length === 1) {
        month = '0' + month;
    }
    if (key) {
        month = monthsObj[key as (keyof typeof monthsObj)];
    }
    return month;
  }
  const checkBirthday = () => {
    if (ctx.seletedBirthDate === '' && ctx.seletedBirthMonth === '' && ctx.seletedBirthYear === '') {
      birthdayError.current = 1;
      return true;
    } else if (ctx.seletedBirthDate === '' || ctx.seletedBirthMonth === '' || ctx.seletedBirthYear === '') {
        birthdayError.current = 3;
      return true;
    } else if (!dateRegex.test(ctx.seletedBirthDate) || !yearRegex.test(ctx.seletedBirthYear) || !monthRegex.test(ctx.seletedBirthMonth)) {
        birthdayError.current = 3;
      return true;
    } else {
      if (parseInt(ctx.seletedBirthDate) > 31) {
        birthdayError.current = 3;
        return true;
      }
      let month = covertMonthToNumberStr('birth');
      ctx.setSeletedBirthMonthNumStr(month);
      if (parseInt(month) > 12) {
        birthdayError.current = 3;
        return true;
      }
      const selectedBirth = new Date(parseInt(ctx.seletedBirthYear), parseInt(month) - 1, parseInt(ctx.seletedBirthDate));
      if (selectedBirth > today) {
        birthdayError.current = 3;
        return true;
      }
      birthdayError.current = 0;
    }
    return false;
  }

  const checkIfAccidentDateBeforeBirthday = () => {
    const monthAccident = covertMonthToNumberStr('accident');
    const monthBirth = covertMonthToNumberStr('birth');
    if (ctx.seletedAccidentDate === '' && ctx.seletedAccidentMonth === '' && ctx.seletedAccidentYear === '') {
        accidentDateBeforeBirthdayError.current = false;
        return false;
    } else if (parseInt(ctx.seletedAccidentYear) < parseInt(ctx.seletedBirthYear)) {

        accidentDateBeforeBirthdayError.current = true;
        return true;
    } else if ((parseInt(ctx.seletedBirthYear) === parseInt(ctx.seletedAccidentYear)) && (parseInt(monthAccident) < parseInt(monthBirth))) {
        accidentDateBeforeBirthdayError.current = true;
        return true;
    } else if ((parseInt(ctx.seletedBirthYear) === parseInt(ctx.seletedAccidentYear)) && (parseInt(monthAccident) === parseInt(monthBirth))
       && (parseInt(ctx.seletedAccidentDate) <= parseInt(ctx.seletedBirthDate))) {
        accidentDateBeforeBirthdayError.current = true;
        return true;
    } else {
        accidentDateBeforeBirthdayError.current = false;
      return false;
    }
  }

  const checkAccidentDate = () => {
    let month = covertMonthToNumberStr('accident');
    ctx.setSeletedAccidentMonthNumStr(month);
    if (ctx.seletedAccidentDate === '' && ctx.seletedAccidentMonth === '' && ctx.seletedAccidentYear === '') {
      accidentDateError.current = 0;
      return false;
    } else if (ctx.seletedAccidentDate === '' || ctx.seletedAccidentMonth === '' || ctx.seletedAccidentYear === '') {
      accidentDateError.current = 3;
      return true;
    } else if (!dateRegex.test(ctx.seletedAccidentDate) || !yearRegex.test(ctx.seletedAccidentYear) || !monthRegex.test(ctx.seletedAccidentMonth)) {
        accidentDateError.current = 3;
        return true;
    } else {
      if (parseInt(ctx.seletedAccidentDate) > 31) {
        accidentDateError.current = 3;
        return true;
      }
      if (parseInt(month) > 12) {
        accidentDateError.current = 3;
        return true;
      }
      const selectedAccident = new Date(parseInt(ctx.seletedAccidentYear), parseInt(month) - 1, parseInt(ctx.seletedAccidentDate));
      if (selectedAccident > today) {
        accidentDateError.current = 3;
        return true;
      }
      accidentDateError.current = 0;
    }
    return false;
  }

  const noClaimNumberCheckedChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    ctx.setnoClaimNumberCheckedClaimant(event.target.checked);
    if (!changed) {
      changed = true;
      props.onChanged();
    }
  }

  const clearError = () => {
    //setBirthdayError(0);
    birthdayError.current = 0;
    accidentDateError.current = 0;
    setFirstNameError(0);
    setLastNameError(0);
    setClaimnumberError(0);
    setClaimNumberIsValid(true);
    setSubmitClicked(false);
    accidentDateBeforeBirthdayError.current = false;
  }

  const onNext = () => {
    setTimeout(() => {
      if (!isInProgress) {
        setIsInProgress(true);
      } else {
        return;
      }

      setSubmitClicked(true);

      validationFailed.current = false;
      if (checkLastName()) {
        validationFailed.current = true;
        setIsInProgress(false);
      }

      if (checkBirthday()) {
        validationFailed.current = true;
        setIsInProgress(false);
      }

      if (checkAccidentDate()) {
        validationFailed.current = true;
        setIsInProgress(false);
      }

      if(checkIfAccidentDateBeforeBirthday()) {
        validationFailed.current = true;
        setIsInProgress(false);
      }

      if (ctx.isUncd) {
        if (checkFirstName()) {
          validationFailed.current  = true;
          setIsInProgress(false);
        }

        if (firstnameError !== 0 || lastnameError !== 0 || birthdayError.current !== 0 || accidentDateError.current !== 0
          || accidentDateBeforeBirthdayError.current || !ctx.noClaimNumberCheckedClaimant) {
          validationFailed.current = true;
          setIsInProgress(false);
        }
      } else {
        if (checkClaimNumber()) {
          validationFailed.current = true;
          setIsInProgress(false);
        }

        if (lastnameError !== 0 || birthdayError.current !== 0 || claimnumberError !== 0) {
          validationFailed.current = true;
          setIsInProgress(false);
        }
      }

      const birDate = ctx.seletedBirthDate.length === 1 ? '0' + ctx.seletedBirthDate : ctx.seletedBirthDate;
      const accDate = ctx.seletedAccidentDate.length === 1 ? '0' + ctx.seletedAccidentDate : ctx.seletedAccidentDate;
      ctx.setSeletedAccidentDate(accDate);
      ctx.setSeletedBirthDate(birDate);
      // validate OK
      if (!validationFailed.current) {
        if (!ctx.isUncd) {
          const edobAuthUrl = process.env.REACT_APP_edob_auth;
          const monthBirth = covertMonthToNumberStr('birth');
          const dobString = `${birDate}/${monthBirth}/${ctx.seletedBirthYear}`;
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              claimNumber: ctx.claimnumberClaimant,
              lastName: ctx.lastnameClaimant.trim(),
              dateOfBirth: dobString
            })
          };
          fetch(edobAuthUrl!, requestOptions)
          .then(response => response.json())
          .then(data => {
            if (data.body.status && data.body.status === 'true') {
              if (ctx.prevClaimnumberClaimant !== ctx.claimnumberClaimant) {
                ctx.setDocumentUploadQueue([]);
                ctx.setPrevClaimnumberClaimant(ctx.claimnumberClaimant);
              }
              props.onValidated();
            }
            if (data.body.status && data.body.status === 'false') {
              setClaimNumberIsValid(false);
              setIsInProgress(false);
            }
          })
          .catch(error => {
            setServerError(true);
            setIsInProgress(false);
            console.error('edob auth service error!', error);
          });
        } else {
          props.onValidated();
        }
      }
    }, 100);
  }
  const onSubmit = async (event: React.MouseEvent) => {
    event.preventDefault();
    clearError();
    await onNext();
  }

  const logout = () => {
    const logoutUrl = i18n.resolvedLanguage === 'fr' ? 'https://services.wsib.on.ca/edob/?lang=fr' : 'https://services.wsib.on.ca/edob/?lang=en';
    const logoutRequest = {
        account: instance.getActiveAccount(),
        postLogoutRedirectUri: logoutUrl
    }
    instance.logoutRedirect(logoutRequest);
  }

  const closeNavigationAwayDialog = (event: React.MouseEvent) => {
    event.preventDefault();
    setNavigationOpen(false);
  }

  const onChangedDate = (value: string) => {
    ctx.setSeletedBirthDate(value);
  }

  const onChangedAccidentDate = (value: string) => {
    ctx.setSeletedAccidentDate(value);
  }

  const onChangedBirthMonth = (value: string) => {
    ctx.setSeletedBirthMonth(value);
  }

  const onChangedAccidentMonth = (value: string) => {
    ctx.setSeletedAccidentMonth(value);
  }

  const onChangedBirthYear = (value: string) => {
    ctx.setSeletedBirthYear(value);
  }

  const onChangedAccidentYear = (value: string) => {
    ctx.setSeletedAccidentYear(value);
  }

  return (
      <Container>
      <Grid container role="region" aria-labelledby="region-info">
        <NavigationAwayDialog
          isOpen={navigationOpen}
          handleClose={closeNavigationAwayDialog}
          handleLogout={logout}
        >
        </NavigationAwayDialog>
        <Grid item xs={12} className="row">
          {ctx.isUncd && <div><Typography variant="h1" id="region-info">{t("claimant-info") + ' (' + t('addUncdStepLabel') + ')'}</Typography></div> }
          {!ctx.isUncd && <div><Typography variant="h1" id="region-info">{t("claimant-info") + ' (' + t('addEdobStepLabel') + ')'}</Typography></div> }
          <br role="presentation"/>
        </Grid>
        {serverError &&
          <div role="alert">
            <Grid item className="row mb-2">
              <div className="col">
                <br role="presentation" />
                <div><Typography variant="h3">{t('error_message_contact_wsib1')}</Typography></div>
              </div>
            </Grid>
            <Grid item className="row mb-4">
              <div className="col">
                {t("error_message_contact_wsib2")}
                {i18n.resolvedLanguage === 'en' && <a className="link" href="https://www.wsib.ca/en/contact-us"
                  target="_blank" rel="noopener noreferrer">
                  {t("error_message_contact_wsib3")}.</a>}
                {i18n.resolvedLanguage === 'fr' && <a className="link" href="https://www.wsib.ca/fr/contactez-nous"
                  target="_blank" rel="noopener noreferrer">
                  {t("error_message_contact_wsib3")}.</a>}
              </div>
            </Grid>
          </div>
        }
        {!serverError &&
          <Grid item className="row mb-2">
              <div className="form">
                <p style={{ marginBottom: '14px'}}>{t("claimant-info-mandatory-label")}</p>
                {ctx.isUncd && accidentDateBeforeBirthdayError.current &&
                  <Typography className="error" role="alert">{t("error_injury_date_before_birth")}</Typography>
                }
                {!claimNumberIsValid &&
                  <Typography className="error" color="error" role="alert" >{t("error.message.auth.error")}</Typography>
                }
                <form style={{ marginTop: '14px'}}>
                  <div className="contactForm">
                    {!ctx.isUncd && <TextField autoFocus className="claimant-input" id="claim-number" autoComplete="off"
                      label={t("claimant-info-claimnumber-label")} fullWidth InputLabelProps={{ shrink: true }} size="small"
                      error={claimnumberError !== 0} helperText={claimnumberError !== 0 ? <span aria-live="assertive" role="alert">{getClaimnumberHelpText(claimnumberError)}</span> : getClaimnumberHelpText(claimnumberError)}
                      value={ctx.claimnumberClaimant} onChange={claimnumberChangedHandler}>
                    </TextField>}
                    {ctx.isUncd && <TextField autoFocus className="claimant-input" id="first-name" autoComplete="off"
                      label={t("claimant-info-firstname-label")} fullWidth InputLabelProps={{ shrink: true }} size="small"
                      error={firstnameError !== 0} helperText={firstnameError !== 0 ? <span aria-live="assertive" role="alert">{getFirstnameHelpText(firstnameError)}</span> : getFirstnameHelpText(firstnameError)}
                      value={ctx.firstnameClaimant} onChange={firstnameChangedHandler}>
                    </TextField>}
                    <TextField className="claimant-input" id="last-name" autoComplete="off"
                      label={t("claimant-info-lastname-label")} fullWidth InputLabelProps={{ shrink: true }} size="small"
                      error={lastnameError !== 0} helperText={lastnameError !== 0 ? <span aria-live="assertive" role="alert">{getLastnameHelpText(lastnameError)}</span> : getLastnameHelpText(lastnameError)}
                      value={ctx.lastnameClaimant} onChange={lastnameChangedHandler}>
                    </TextField>
                    <fieldset id="birthdatecontrol" style={{ maxWidth: "550px", marginLeft: "5px", border: "none", margin: "0px", padding: "0px" }}>
                      <legend style={{ backgroundColor: "#FFFFFF", color: "#333333" }}>{t("claimant-info-date-of-birth-label")}</legend>
                      <div id="birthdatedropdownlabels" style={{ display: "flex", flexDirection: "row", marginBottom: "8px" }}>
                        <label style={{ minWidth: '46px', marginRight: '5px', flexGrow: 1, width: '-webkit-fill-available', backgroundColor: "#FFFFFF", color: "#333333", fontSize: "14px" }} id="birth-day-label" htmlFor="birthdate-select">{t("day-label")}</label>
                        <label style={{ minWidth: '46px', marginRight: '5px', flexGrow: 1, width: '-webkit-fill-available', backgroundColor: "#FFFFFF", color: "#333333", fontSize: "14px" }} id="birth-month-label" htmlFor="birthmonth-select">{t("month-label")}</label>
                        <label style={{ minWidth: '62px', marginRight: '5px', flexGrow: 1, width: '-webkit-fill-available', backgroundColor: "#FFFFFF", color: "#333333", fontSize: "14px" }} id="birth-year-label" htmlFor="birthyear-select">{t("year-label")}</label>
                      </div>
                      <div id="birthdatedropdown" style={{ display: "flex", flexDirection: "row" }}>
                        <CalendarDropdownList id='birthdate' type='day of birth' defaultValue={ctx.seletedBirthDate} onChangedDate={onChangedDate}/>
                        {i18n.resolvedLanguage === 'fr' && <CalendarDropdownList id='birthmonthFr' type='month of birth' defaultValue={ctx.seletedBirthMonth} onChangedDate={onChangedBirthMonth}/>}
                        {i18n.resolvedLanguage === 'en' && <CalendarDropdownList id='birthmonth' type='month of birth' defaultValue={ctx.seletedBirthMonth} onChangedDate={onChangedBirthMonth}/>}
                        <CalendarDropdownList id='birthyear' type='year of birth' defaultValue={ctx.seletedBirthYear} onChangedDate={onChangedBirthYear}/>
                      </div>
                    </fieldset>
                    { birthdayError.current === 0 && <FormHelperText id="dateof-birth-helper-text" style={{ marginLeft: '14px', marginTop: '4px', marginBottom: "24px" }} error={false}>{getBirthdayDateHelpText(0)}</FormHelperText>}
                    {(submitClicked && birthdayError.current !== 0) && <FormHelperText id="dateof-birth-helper-text" style={{ marginLeft: '14px', marginTop: '4px', marginBottom: "24px" }} error={true}><span aria-live="assertive" role="alert">{getBirthdayDateHelpText(birthdayError.current)}</span></FormHelperText>}
                    {ctx.isUncd && <fieldset id="accidentdatecontrol" style={{  maxWidth: "550px", marginLeft: "5px", border: "none", margin: "0px", padding: "0px" }}>
                      <legend style={{ marginTop: "0px", backgroundColor: "#FFFFFF", color: "#333333" }}>{t("claimant-info-date-of-accident-label")}</legend>
                      <div id="accidentdatedropdownlabels" style={{ display: "flex", flexDirection: "row", marginBottom: "8px" }}>
                        <label style={{ minWidth: '46px', marginRight: '5px', flexGrow: 1, width: '-webkit-fill-available', backgroundColor: "#FFFFFF", color: "#333333", fontSize: "14px" }} id="accident-day-label" htmlFor="accidentdate-select">{t("day-label")}</label>
                        <label style={{ minWidth: '46px', marginRight: '5px', flexGrow: 1, width: '-webkit-fill-available', backgroundColor: "#FFFFFF", color: "#333333", fontSize: "14px" }} id="accident-month-label" htmlFor="accidentmonth-select">{t("month-label")}</label>
                        <label style={{ minWidth: '62px', marginRight: '5px', flexGrow: 1, width: '-webkit-fill-available', backgroundColor: "#FFFFFF", color: "#333333", fontSize: "14px" }} id="accident-year-label" htmlFor="accidentyear-select">{t("year-label")}</label>
                      </div>
                      <div id="accidentdatedropdown" style={{ display: "flex", flexDirection: "row" }}>
                        <CalendarDropdownList id='accidentdate' type='day of injury, illness or exposure' defaultValue={ctx.seletedAccidentDate} onChangedDate={onChangedAccidentDate}/>
                        {i18n.resolvedLanguage === 'fr' && <CalendarDropdownList id='accidentmonthFr' type='month of injury, illness or exposure' defaultValue={ctx.seletedAccidentMonth} onChangedDate={onChangedAccidentMonth}/>}
                        {i18n.resolvedLanguage === 'en' && <CalendarDropdownList id='accidentmonth' type='month of injury, illness or exposure' defaultValue={ctx.seletedAccidentMonth} onChangedDate={onChangedAccidentMonth}/>}
                        <CalendarDropdownList id='accidentyear' type='year of injury, illness or exposure' defaultValue={ctx.seletedAccidentYear} onChangedDate={onChangedAccidentYear}/>
                      </div>
                    </fieldset>}
                    { (ctx.isUncd && accidentDateError.current === 0) && <FormHelperText style={{ marginLeft: '14px', marginTop: '4px', marginBottom: "24px" }} error={false}>{getAccidentDateHelpText(0)}</FormHelperText>}
                    {(submitClicked && accidentDateError.current !== 0) && <FormHelperText style={{ marginLeft: '14px', marginTop: '4px', marginBottom: "24px" }} error={true}><span aria-live="assertive" role="alert">{getAccidentDateHelpText(accidentDateError.current)}</span></FormHelperText>}
                    {ctx.isUncd && <Typography variant="body1" variantMapping={{ body1: 'div' }}>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox checked={ctx.noClaimNumberCheckedClaimant} onChange={noClaimNumberCheckedChangeHandler} />} label={t("claimant-info-checkbox-label")} />
                      </FormGroup>
                    </Typography>}
                    {(ctx.isUncd && submitClicked && !ctx.noClaimNumberCheckedClaimant) && <FormHelperText style={{ marginTop: '0px' }} error={true}><span aria-live="assertive" role="alert">{t("confirm_no_claim_number")}</span></FormHelperText>}
                    <p></p><p></p>
                    <div>
                    <button className="navbtn" type="button" id="prevToStarted" onClick={props.onPrev} aria-label={t("button.previous") + t("gotoLabel") + t("step1Label")}>{t("button.previous")}</button><button className="btn-wsib" type="submit" aria-label={t("label.next") + t("gotoLabel") + t("step3Label")}
                        onClick={onSubmit}>{t("label.next")}</button>
                    </div>
                  </div>
                </form>
              </div>
         
          </Grid>
        }
      </Grid>
      </Container>
  );
}

export default ClaimantInfo;