import React, { useState, useContext, useEffect, useRef } from 'react';
import './DocUpload.css';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { useMsal } from "@azure/msal-react";
import { allowedFileTypes, UncdContext } from "./StateProvider"
import FileTypeInfoDialog from "../dialogs/FileTypeInfoDialog";
import UploadInProgressDialog from "../dialogs/UploadInProgressDialog";
import FileDropzone from './FileDropzone';
import FileItem, { FileItemCallback } from '../models/FileItem';
import FileUploader from '../services/FileUploader';
import { FailedFileListsType, getMainFormNameList, getToken, ParsedResponseHeaders, StepComponentProps, getWsibFormNameList, getCategoriesNameList, getNoWsibFormNameList } from "../Helper";
import { DropEvent, FileRejection } from 'react-dropzone';
import { Forms } from '../models/Forms';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { AnalyticsLink, LinkKey } from '../services/AnalyticsLink';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IsThisWsibDialog from "../dialogs/IsThisWsibDialog";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Categories } from '../models/Categories';
import { EdobForms } from '../models/EdobForms';
import IsCategoryDialog from '../dialogs/IsCategoryDialog';
import IsSubDocumentDialog from '../dialogs/IsSubDocumentDialog';
import Link from '@mui/material/Link';
import FormDropdownList from './FormDropdownList';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import SubFormDropdownList from './SubFormDropdownList';

const DocUpload: React.FC<StepComponentProps> = (props) => {
    const { instance } = useMsal();
    const ctx = useContext(UncdContext);
    const { t, i18n } = useTranslation();
    const [submitClicked, setSubmitClicked] = useState(false);
    const [failedFileLists, setFailedFileLists] = useState<FailedFileListsType>({
        serverError: false,
        fileWrongSizeList: [],
        fileWrongTypeList: [],
        filenameInvalidList: [],
        fileVirusList: [],
        maxFileNumReached: false
    });
    const [noDocumentError, setNoDocumentError] = useState(false);

    let fileUploader = new FileUploader(process.env.REACT_APP_uploadFileUrl!);
    if (!ctx.isUncd) {
        fileUploader = new FileUploader(process.env.REACT_APP_edobUploadFileUrl!);
    }

    const [fileTypeInfoOpen, setFileTypeInfoOpen] = useState(false);
    const [isThisWsibOpen, setIsThisWsibOpen] = useState(false);
    const [uploadInProgressOpen, setUploadInProgressOpen] = useState(false);
    const wsibSelectedForms = useRef('');
    const edobSelectedForms = useRef('');
    // const edobSelectedCategpries = useRef('');
    const edobSelectedSubForms = useRef('');
    const sendFlag = useRef(false);
    const [resetSubForms, setResetSubForms] = useState('');
    let wsibForms: EdobForms[] = [];
    let noWsibForms: EdobForms[] = [];

    let maxFiles = ctx.isUncd ? 1 : 5;
    const analyticsLink = new AnalyticsLink();

    //google analytics
    if (!sendFlag.current) {
        if (ctx.isUncd) {
            analyticsLink.sendLinkAnalyticsReport(LinkKey.uploadDoc, i18n.resolvedLanguage);
        } else {
            analyticsLink.sendLinkAnalyticsReport(LinkKey.edobDocUpload, i18n.resolvedLanguage);
        }
        sendFlag.current = true;
    }

    const getEdobFormsList = (isWsib: boolean) => {
        const formlistUrl = isWsib ? process.env.REACT_APP_wsib_FormListUrl : process.env.REACT_APP_noWsib_FormListUrl;
        wsibForms = [];
        noWsibForms = [];
        fetch(formlistUrl!)
        .then(response => {
            if (response.status >= 300) {
                setServerError();
                return;
            }
            return response.json();
        })
        .then(data => {
            if (data) {
;                if (isWsib) {
                    for(let index = 0; index < data.length; index++) {
                        let form: EdobForms = new EdobForms();
                        form.formId = data[index].id;
                        form.formNameEn = data[index].name_en;
                        form.formNameFr = data[index].name_fr;
                        form.ibfEnDisplayOrder = data[index].enDisplayOrder;
                        form.ibfFrDisplayOrder = data[index].frDisplayOrder;
                        form.isWsibForm = data[index].isWsibForm;
                        form.mainFormId = data[index].id;
                        form.descriptionEn = data[index].descriptionEn;
                        form.descriptionFr = data[index].descriptionFr;
                        wsibForms.push(form);
                    }
                    ctx.setWsibFormList(wsibForms);
                } else {
                    let categories: Categories[] = [];
                    for(let index = 0; index < data.length; index++) {
                        let category: Categories = new Categories();
                        category.categoryId = data[index].categoryId;
                        category.categoryNameEn = data[index].categoryNameEn;
                        category.categoryNameFr = data[index].categoryNameFr;
                        category.categoryDescEn = data[index].categoryDescEn;
                        category.categoryDescFr = data[index].categoryDescFr;
                        category.categoryDisplayOrderEn = data[index].categoryDisplayOrderEn;
                        category.categoryDisplayOrderFr = data[index].categoryDisplayOrderFr;
                        let forms = data[index].forms;
                        noWsibForms = [];
                        for(let i = 0; i < forms.length; i++) {
                            let form: EdobForms = new EdobForms();
                            form.formId = forms[i].formId;
                            form.formNameEn = forms[i].formNameEn;
                            form.formNameFr = forms[i].formNameFr;
                            form.ibfEnDisplayOrder = forms[i].ibfEnDisplayOrder;
                            form.ibfFrDisplayOrder = forms[i].ibfFrDisplayOrder;
                            form.isWsibForm = forms[i].isWsibForm;
                            form.mainFormId = forms[i].mainFormId;
                            form.descriptionEn = forms[i].descriptionEn;
                            form.descriptionFr = forms[i].descriptionFr;
                            noWsibForms.push(form);
                        }
                        category.forms = noWsibForms;
                        categories.push(category);
                    }
                    ctx.setCategoryList(categories);
                }
            }
        })
        .catch(err => {
            console.log(err);
            // google analytics
            analyticsLink.sendLinkAnalyticsReport(LinkKey.technical, i18n.resolvedLanguage);
            setServerError();
        });
    };

    useEffect(() => {
        // window.scrollTo(0, 0);
        window.history.pushState('','','/uploadreport');
        document.title = ctx.isUncd ? t("uploadReportTitle") : t("edobUploadReportTitle");
        let noscriptEles = document.getElementsByTagName("noscript");
        for (let i = 0; i < noscriptEles.length; i++) {
            noscriptEles[i].removeAttribute('aria-hidden');
        }
    });

    useEffect(() => {
        ctx.setShowAlertMsg(false);
        const categoryList = ctx.documentUploadQueue.filter((item) => item?.category && item?.category?.categoryNameEn === 'Expense receipt');
        if (categoryList.length > 0) {
            ctx.setShowAlertMsg(true);
        }
    });

    // file item handlers
    const onBeforeUploadItem = async (item: FileItem) => {

        // the token set in uploader object may be expired after a while, so need to
        // refresh the token before sending the update request to server
        const token = await getToken(instance);
        if (token) {
            const newHeaders = item.headers.filter(
                (x) => x.name !== 'Authorization'
            );
            let authToken = 'Bearer ' + token;
            newHeaders.push({ name: 'Authorization', value: authToken });

            item.headers = newHeaders;
        } else {
            //TODO: verify if exception happens without valid token, then show server error message
        }
    };

    const updateQueueState = () => {
        ctx.setDocumentUploadQueue((oldValue) => {
            return [...oldValue];
        })
    }

    const onItemProgress = (item: FileItem, progress: number) => {
        updateQueueState();
    }

    // if server does not return json, then return -1 to indicate server error
    const getErrorCodeFromResponse = (response: string) => {
        try {
            const responseJson = JSON.parse(response);
            const errorCode = responseJson.apiErrorCode;
            if (isNaN(errorCode)) {
                return -1;
            } else {
                return errorCode;
            }
        } catch (err) {
            console.log('Error from server', err);
            return -1;
        }
    }

    const onItemComplete = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
        // result is handled by noItemSuccess and onItemError. 
    }

    const onItemSuccess = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
        if (item.isCancel) {
            return { item, response, status, headers };
        }

        try {
            const responseJson = JSON.parse(response);
            const fileId = responseJson.name;
            if (!fileId) {
                console.log("Fail to get fileID from response as json: " + response);
                onItemError(item, "Fail to get fileID from response", -1, {});
            } else {
                item.alias = fileId;
            }
        }
        catch (err) {
            //if response is not json, then report an error
            console.log("Fail to parse response as json: " + response);
            onItemError(item, "Fail to parse response as json: ", -1, {});
        }

        updateQueueState();
        return { item, response, status, headers };
    }

    const setServerError = () => {
        failedFileLists.serverError = true;
        setFailedFileLists((oldValue) => {
            return { ...oldValue, serverError: true }
        });
    }

    const onItemError = (item: FileItem, response: string, status: number, header: ParsedResponseHeaders) => {
        console.log(response);
        if (status === 400) {
            const errorCode = getErrorCodeFromResponse(response);
            if (errorCode === 202) {
                // file content type rejected by server
                removeDocItem(item, null);
                setFailedFileLists((old) => {
                    if (!old.fileWrongTypeList.includes(item._file.name)) {
                        old.fileWrongTypeList.push(item._file.name);
                        return { ...old };
                    }
                    return old;
                })
            } else {
                // google analytics
                analyticsLink.sendLinkAnalyticsReport(LinkKey.technical, i18n.resolvedLanguage);
                setServerError();
            }
        } else if (status === 403) {
            const errorCode = getErrorCodeFromResponse(response);
            // Error handler for uploader:
            if (errorCode === 501) {
                // virus found
                removeDocItem(item, null);
                setFailedFileLists((old) => {
                    if (!old.fileVirusList.includes(item._file.name)) {
                        old.fileVirusList.push(item._file.name);
                        return { ...old };
                    }
                    return old;
                })
            } else {
                // there was a technical error with virus checker (indicate a back end error)
                // google analytics
                analyticsLink.sendLinkAnalyticsReport(LinkKey.technical, i18n.resolvedLanguage);
                setServerError();

            }
        } else if (status === 404) {
            // file size over backend max limit
            removeDocItem(item, null);
            setFailedFileLists((old) => {
                if (!old.fileWrongSizeList.includes(item._file.name)) {
                    old.fileWrongSizeList.push(item._file.name);
                    return { ...old };
                }
                return old;
            })
        } else {
            // all other status codes are considered a server error scenario
            // google analytics
            analyticsLink.sendLinkAnalyticsReport(LinkKey.technical, i18n.resolvedLanguage);
            setServerError();
        }
        updateQueueState();
    }

    const onItemCancel = (item: FileItem, response: string, status: number, header: ParsedResponseHeaders) => {
        updateQueueState();
    }


    const onfileDialogOpen: () => void = () => {
        setNoDocumentError(false);
    }

    const onfileDialogCancel: () => void = () => {
        if (submitClicked && ctx.documentUploadQueue.length === 0) {
            setNoDocumentError(true);
        }
    }

    //react-dropzone maxFiles property only check file number in a single drag-drop operation, it
    //does not validate the total files in upload queue. So it cannot be used to limit the max file number.
    const onFileSelected: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void = (acceptedFiles, rejectedFiles) => {
        //first reset error of previous file selection
        clearUploaderErrors();

        acceptedFiles.forEach(element => {
            const callback: FileItemCallback = {
                onBeforeUpload: onBeforeUploadItem,
                onProgress: onItemProgress,
                onSuccess: onItemSuccess,
                onError: onItemError,
                onCancel: onItemCancel,
                onComplete: onItemComplete
            };
            const fileItem = new FileItem(element, callback);
            if (!ctx.isUncd) {
                fileItem.claimId = ctx.claimnumberClaimant;
                fileItem.lastName = ctx.lastnameClaimant.trim();
                //let month = ctx.birthdayClaimant ? ctx.birthdayClaimant?.getMonth() + 1 : '';
                //const dobString = `${ctx.birthdayClaimant?.getDate()}/${month}/${ctx.birthdayClaimant?.getFullYear()}`;
                const dobString = `${ctx.seletedBirthDate}/${ctx.seletedBirthMonthNumStr}/${ctx.seletedBirthYear}`;
                fileItem.dateOfBirth = dobString;
            }
            ctx.setDocumentUploadQueue((old) => {
                if (old.length < maxFiles) {
                    return ([...old, fileItem]);
                } else {
                    setFailedFileLists((prevState: FailedFileListsType) => {
                        return { ...prevState, maxFileNumReached: true };
                    })
                    return old;
                }
            })
            fileUploader.uploadItem(fileItem);
            console.log(fileItem, fileUploader);
            console.log("file accepted: " + fileItem._file.name);
        });

        rejectedFiles.forEach(fileRejection => {
            if (fileRejection.errors[0] && fileRejection.errors[0].code === "file-too-large") {
                setFailedFileLists((old) => {
                    if (!old.fileWrongSizeList.includes(fileRejection.file.name)) {
                        old.fileWrongSizeList.push(fileRejection.file.name);
                        return { ...old };
                    }
                    return old;
                })
            } else if (fileRejection.errors[0] && fileRejection.errors[0].code === "file-invalid-type") {
                setFailedFileLists((old) => {
                    if (!old.fileWrongTypeList.includes(fileRejection.file.name)) {
                        old.fileWrongTypeList.push(fileRejection.file.name);
                        return { ...old };
                    }
                    return old;
                })
            } else if (fileRejection.errors[0] && fileRejection.errors[0].code === "file-name-invalid") {
                setFailedFileLists((old) => {
                    if (!old.filenameInvalidList.includes(fileRejection.file.name)) {
                        old.filenameInvalidList.push(fileRejection.file.name);
                        return { ...old };
                    }
                    return old;
                })
            }
            console.log("file rejected: " + fileRejection.file.name + ', ' + fileRejection.errors[0].code);
        });

    };

    // dialog handler
    const showFileFormatInfo = (event: React.MouseEvent) => {
        event.preventDefault();
        setFileTypeInfoOpen(true);
    }

    const showIsThisWsib = (event: React.MouseEvent) => {
        event.preventDefault();
        setIsThisWsibOpen(true);
    }

    const showCategory = (event: React.MouseEvent, item: FileItem) => {
        event.preventDefault();
        item.isCategoryOpen = true;
        updateQueueState();
    }

    const showSubDocument = (event: React.MouseEvent, item: FileItem) => {
        event.preventDefault();
        item.isSubDocumentOpen = true;
        updateQueueState();
    }

    const keyDownHandler = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.code === "Enter") {
            setFileTypeInfoOpen(true);
        }
    };

    const keyDownIsThisWsibHandler = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.code === "Enter") {
            setIsThisWsibOpen(true);
        }
    };

    const keyDownCategoryHandler = (event: React.KeyboardEvent<HTMLButtonElement>, item: FileItem) => {
        if (event.code === "Enter") {
            item.isCategoryOpen=true;
        }
    };

    const keyDownSubDocumentHandler = (event: React.KeyboardEvent<HTMLButtonElement>, item: FileItem) => {
        if (event.code === "Enter") {
            item.isSubDocumentOpen=true;
        }
    };

    const closeFileTypeInfoOpenDialog = (event: React.MouseEvent) => {
        event.preventDefault();
        setFileTypeInfoOpen(false);
    }

    const closeIsThisWsibDialog = (event: React.MouseEvent) => {
        event.preventDefault();
        setIsThisWsibOpen(false);
    }

    const closeCategoryDialog = (event: React.MouseEvent, item: FileItem) => {
        event.preventDefault();
        item.isCategoryOpen = false;
        updateQueueState();
    }

    const closeSubDocumentDialog = (event: React.MouseEvent, item: FileItem) => {
        event.preventDefault();
        item.isSubDocumentOpen = false;
        updateQueueState();
    }

    const showUploadInProcessDialog = () => {
        setUploadInProgressOpen(true);
    }

    const closeUploadInProgressDialog = (event: React.MouseEvent) => {
        event.preventDefault();
        setUploadInProgressOpen(false);
    }

    const onRemoveHandler = (fileItem: FileItem, event: React.MouseEvent) => {
        clearUploaderErrors();
        //if the last document is removed, then reset submit flag.
        if (ctx.documentUploadQueue.length === 1) {
            setSubmitClicked(false);
        }
        removeDocItem(fileItem, event);
    };

    const removeDocItem = (fileItem: FileItem, event: React.MouseEvent | null) => {
        if (event) {
            event.preventDefault();
        }

        if (fileItem.isUploading) {
            fileItem.cancel();
        }

        ctx.setDocumentUploadQueue((oldQueue) => {
            const docQueue = oldQueue.filter(item => item !== fileItem);
            return docQueue;
        })

        //after main document is removed, reset all supporting documents
        ctx.setSupportDocumentUploadQueue([]);
        ctx.setHasSupportDoc("");
    }

    // customization to only show progress up to 99% before the uploading status changed to uploaded
    const getItemProgress = (item: FileItem) => {
        // console.log(item._file.name + " progress: " + item.progress);
        if (item.progress !== 100) {
            return item.progress;
        } else {
            if (item.isUploaded) {
                return item.progress;
            } else {
                return 99;
            }
        }
    };

    /**
    * Clear the file error messages.
    */
    const clearUploaderErrors = () => {
        setFailedFileLists({
            serverError: false,
            fileWrongSizeList: [],
            fileWrongTypeList: [],
            filenameInvalidList: [],
            fileVirusList: [],
            maxFileNumReached: false
        });
        setNoDocumentError(false);
    }

    const onSubmit = () => {
        return new Promise (resolve => setTimeout(() => {
            setSubmitClicked(true);

            if (ctx.documentUploadQueue.length === 0) {
                // show no document to submit error (MSG-14)
                window.scrollTo(0, 0);
                setNoDocumentError(true);
                return;
            }
            for (const item of ctx.documentUploadQueue) {
                // show progress dialog if document is still uploading or not yet start to upload
                if (!item.wsibForm && !item.edobForm && !item.subEdobForm) {
                    return;
                }
            }

            for (const item of ctx.documentUploadQueue) {
                // show progress dialog if document is still uploading or not yet start to upload
                if (item.isUploading || !item.isSuccess) {
                    showUploadInProcessDialog();
                    return;
                }
            }
            const birthdate = new Date(Number(ctx.seletedBirthYear), Number(ctx.seletedBirthMonthNumStr) - 1, Number(ctx.seletedBirthDate));
            ctx.setbirthdayClaimant(birthdate);
            if (ctx.seletedAccidentDate && ctx.seletedAccidentDate !== '') {
                const accidentDate = new Date(Number(ctx.seletedAccidentYear), Number(ctx.seletedAccidentMonthNumStr) - 1, Number(ctx.seletedAccidentDate))
                ctx.setaccidentDateClaimant(accidentDate);
            } else {
                ctx.setaccidentDateClaimant(null);
            }

            props.onValidated();
        }, 100));
    }
    const onNext = async () => {
        clearUploaderErrors();
        await onSubmit();
    }

    const isWsibSwitchHandle = (event: React.ChangeEvent<HTMLInputElement>, item: FileItem)=>{
        event.preventDefault();

        item.category = null;
        item.edobForm = null;
        item.noWsibFormList = [];
        item.isWsibTouched = true;
        if (event.target.value.startsWith('yes')) {
            if (ctx.wsibFormList && ctx.wsibFormList.length === 0) {
                getEdobFormsList(true);
            }
            item.isWsibForm = 'yes';
            ctx.setIsWsib(true);
        } else {
            if (ctx.categoryList && ctx.categoryList.length === 0) {
                getEdobFormsList(false);
            }
            item.isWsibForm = 'no';
            ctx.setIsWsib(false);
        }
        updateQueueState();
    }

    const getWsibForm = (value: string) => {
        let newForm: Forms[] = [];

        if (i18n.resolvedLanguage === 'en') {
            newForm = ctx.formList.filter((form) => {
                return value.trim() === form.formNameEn.replaceAll('’', '\'');
            });
        } else {
            newForm = ctx.formList.filter((form) => {
                return value.trim() === form.formNameFr.replaceAll('’', '\'');
            });
        }

        return newForm[0];
    }

    const switchWsibForm = (value: string) => {
        let newForm: Forms[] = [];

        if (i18n.resolvedLanguage === 'en') {
            newForm = ctx.formList.filter((form) => {
                return value === form.formNameFr.replaceAll('’', '\'')
            });
        } else {
            newForm = ctx.formList.filter((form) => {
                return value === form.formNameEn.replaceAll('’', '\'')
            });
        }

        return newForm[0];
    }

    const getEdobForm = (value: string) => {
        let newForm: EdobForms[] = [];

        if (i18n.resolvedLanguage === 'en') {
            newForm = ctx.wsibFormList.filter((form) => {
                return value === form.formNameEn.replaceAll('’', '\'');
            });
        } else {
            newForm = ctx.wsibFormList.filter((form) => {
                return value === form.formNameFr.replaceAll('’', '\'');
            });
        }

        return newForm[0];
    }

    const switchEdobForm = (value: string) => {
        let newForm: EdobForms[] = [];

        if (i18n.resolvedLanguage === 'en') {
            newForm = ctx.wsibFormList.filter((form) => {
                return value.replaceAll('’', '\'') === form.formNameFr.replaceAll('’', '\'');
            });
        } else {
            newForm = ctx.wsibFormList.filter((form) => {
                return value.replaceAll('’', '\'') === form.formNameEn.replaceAll('’', '\'');
            });
        }

        return newForm[0];
    }

    const getCategory = (value: string) => {
        let newCategory: Categories[] = [];

        if (i18n.resolvedLanguage === 'en') {
            newCategory = ctx.categoryList.filter((category) => {
                return value === category.categoryNameEn.replaceAll('’', '\'');
            });
        } else {
            newCategory = ctx.categoryList.filter((category) => {
                return value === category.categoryNameFr.replaceAll('’', '\'');
            });
        }

        return newCategory[0];
    }

    const switchCategory = (value: string) => {
        let newCategory: Categories[] = [];

        if (i18n.resolvedLanguage === 'en') {
            newCategory = ctx.categoryList.filter((category) => {
                return value === category.categoryNameFr.replaceAll('’', '\'');
            });
        } else {
            newCategory = ctx.categoryList.filter((category) => {
                return value === category.categoryNameEn.replaceAll('’', '\'');
            });
        }

        return newCategory[0];
    }

    const getSubForm = (value: string, item: FileItem) => {
        let newForm: EdobForms[] = [];

        if (i18n.resolvedLanguage === 'en') {
            newForm = item.noWsibFormList.filter((form) => {
                return value === form.formNameEn.replaceAll('’', '\'');
            });
        } else {
            newForm = item.noWsibFormList.filter((form) => {
                return value === form.formNameFr.replaceAll('’', '\'');
            });
        }

        return newForm[0];
    }

    const switchSubForm = (value: string, item: FileItem) => {
        let newForm: EdobForms[] = [];

        if (i18n.resolvedLanguage === 'en') {
            newForm = item.noWsibFormList.filter((form) => {
                return value === form.formNameFr.replaceAll('’', '\'');
            });
        } else {
            newForm = item.noWsibFormList.filter((form) => {
                return value === form.formNameEn.replaceAll('’', '\'');
            });
        }

        return newForm[0];
    }

    const formTypeChanged = (value: string, item: FileItem) => {
        wsibSelectedForms.current = value;
        const newForm = getWsibForm(value);
        //if old form id is different from new form id, then reset all supporting documents
        if (item.wsibForm?.formId && (item.wsibForm?.formId !== newForm?.formId!))
        {
            ctx.setSupportDocumentUploadQueue([]);
            ctx.setHasSupportDoc("");
        }
        item.wsibForm = newForm;

        updateQueueState();
    }

    const edobFormTypeChanged = (value: string, item: FileItem) => {
        edobSelectedForms.current = value;
        const newForm = getEdobForm(value);
        ctx.setSupportDocumentUploadQueue([]);
        ctx.setHasSupportDoc("");
        if (typeof newForm !== 'undefined') {
            item.edobForm = newForm;
        } else {
            item.edobForm = null;
        }

        updateQueueState();
    }

    const categoryChanged = (value: string, item: FileItem) => {
        // edobSelectedCategpries.current = value;
        const newCategory = getCategory(value);
        //if old form id is different from new form id, then reset all supporting documents
        ctx.setSupportDocumentUploadQueue([]);
        ctx.setHasSupportDoc("");
        if (newCategory) {
            item.category = newCategory!;
            item.noWsibFormList = newCategory!.forms!;
        } else {
            item.category = null;
        }
        item.subEdobForm = null;

        updateQueueState();
    }

    const subFormChanged = (value: string, item: FileItem) => {
        edobSelectedSubForms.current = value;
        const newForm = getSubForm(value, item);
        //if old form id is different from new form id, then reset all supporting documents
        ctx.setSupportDocumentUploadQueue([]);
        ctx.setHasSupportDoc("");
        item.subEdobForm = newForm;


        updateQueueState();
    }

    const getDefaultValue = (item: FileItem) => {
        if (item.wsibForm) {
            return i18n.resolvedLanguage === 'en' ? item.wsibForm.formNameEn : item.wsibForm.formNameFr;
        } else {
            return '';
        }
    }

    const getDefaultEdobValue = (item: FileItem) => {
        if (item.edobForm) {
            return i18n.resolvedLanguage === 'en' ? item.edobForm.formNameEn : item.edobForm.formNameFr;
        } else {
            return '';
        }
    }

    const getDefaultCategoryValue = (item: FileItem) => {
        if (item.category) {
            return i18n.resolvedLanguage === 'en' ? item.category?.categoryNameEn : item.category?.categoryNameFr;
        } else {
            return '';
        }
    }

    const getDefaultSubformsValue = (item: FileItem) => {
        if (item.subEdobForm) {
            return i18n.resolvedLanguage === 'en' ? item.subEdobForm.formNameEn : item.subEdobForm.formNameFr;
        } else {
            return '';
        }
    }

    const resetSubFormsHandler = (id: string) => {
        setResetSubForms(id);
    }

    return (
            <Container>
                <Grid container role="region" rowSpacing={1} aria-labelledby="region-upload">
                    {ctx.isUncd && <Grid item xs={12} className="row">
                        {t('all_field_are_mandatory')}
                    </Grid>}
                    {!ctx.isUncd && <Grid item xs={12} className="row">
                        <div><Typography variant="h1" id="region-upload">{t("message.dob.documentation.submit") + ' (' + t('edobUploadStepLabel') + ')'}</Typography></div>
                        <div><Typography variant="h2" style={{marginTop: 10}}>{t("label.claim.number")}{ctx.claimnumberClaimant}</Typography></div>
                        <br role="presentation"/>
                    </Grid>}
                    {ctx.isUncd && <Grid item xs={12} className="row">
                        <div><Typography variant="h1" id="region-upload">{t("document-upload") + ' (' + t('uncdUploadStepLabel') + ')'}</Typography></div>
                        <br role="presentation"/>
                    </Grid>}
                    {failedFileLists.serverError && <React.Fragment>
                        <Grid item xs={12} className="row mb-2" role="alert" >

                            <div><Typography variant="h2">{t('error_message_contact_wsib1')}</Typography></div>
                        </Grid>
                        <Grid item xs={12} className="row mb-4" role="alert" >
                            {t("error_message_contact_wsib2")}
                            {i18n.resolvedLanguage === 'en' && <a className="link" href="https://www.wsib.ca/en/contact-us"
                                target="_blank" rel="noopener noreferrer">
                                {t("error_message_contact_wsib3")}.</a>}
                            {i18n.resolvedLanguage === 'fr' && <a className="link" href="https://www.wsib.ca/fr/contactez-nous"
                                target="_blank" rel="noopener noreferrer">
                                {t("error_message_contact_wsib3")}.</a>}
                        </Grid>
                    </React.Fragment>}

                    {!failedFileLists.serverError &&
                        <React.Fragment>

                            {(noDocumentError && failedFileLists.fileWrongSizeList.length === 0 &&
                                failedFileLists.fileWrongTypeList.length === 0 && failedFileLists.filenameInvalidList.length === 0 &&
                                failedFileLists.fileVirusList.length === 0) &&
                                <Grid item xs={12} className="row mb-2">

                                    <Typography className="error" id="no-document-error" color="error" aria-live="assertive" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error_message_no_doc")}</Typography>

                                </Grid>
                            }
                            {failedFileLists.fileWrongSizeList.length > 0 &&
                                <Grid item xs={12} className="row mb-2">

                                    <Typography  className="error" color="error" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error_message_file_size_too_big")}
                                        {failedFileLists.fileWrongSizeList.map((file, index) => {
                                            return (
                                                <div key={index.toString()} role="alert" className="ml-4 mt-1 doclist">
                                                    {file}
                                                </div>);
                                        })}
                                    </Typography>

                                </Grid>
                            }
                            {failedFileLists.fileWrongTypeList.length > 0 &&
                                <Grid item xs={12} className="row mb-2">

                                    <Typography className="error" color="error" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error_message_file_type_invalid")} {allowedFileTypes.join(', ')}
                                        {failedFileLists.fileWrongTypeList.map((file, index) => {
                                            return (
                                                <div key={index.toString()} role="alert" className="ml-4 mt-1 doclist">
                                                    {file}
                                                </div>);
                                        })}
                                    </Typography>

                                </Grid>
                            }
                            {failedFileLists.filenameInvalidList.length > 0 &&
                                <Grid item xs={12} className="row mb-2">

                                    <Typography className="error" color="error" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error_message_file_name_invalid")}
                                        {failedFileLists.filenameInvalidList.map((file, index) => {
                                            return (
                                                <div key={index.toString()} role="alert" className="ml-4 mt-1 doclist">
                                                    {file}
                                                </div>);
                                        })}
                                    </Typography>

                                </Grid>
                            }
                            {failedFileLists.fileVirusList.length > 0 &&
                                <Grid item xs={12} className="row mb-2">

                                    <Typography className="error" color="error" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error_message_virus_error")}
                                        {failedFileLists.fileVirusList.map((file, index) => {
                                            return (
                                                <div key={index.toString()} role="alert" className="ml-4 mt-1 doclist">
                                                    {file}
                                                </div>);
                                        })}

                                        <div>{t("error_message_virus_error2")}</div>
                                        <div>{t("error_message_virus_error3")}</div>
                                        <div>{t("error_message_virus_error4")}</div>
                                        <div>{t("error_message_virus_error5")}</div>
                                    </Typography>

                                </Grid>
                            }
                            {failedFileLists.maxFileNumReached && ctx.isUncd &&
                                <Grid item xs={12} className="row mb-2">
                                    <Typography className="error" color="error" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error_message_upload_max_1_dob_files")}</Typography>
                                </Grid>
                            }
                            {failedFileLists.maxFileNumReached && !ctx.isUncd &&
                                <Grid item xs={12} className="row mb-2">
                                    <Typography className="error" color="error" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error_message_upload_max_5_edob_dob_files")}</Typography>
                                </Grid>
                            }
                            <Grid item xs={12} >

                                {ctx.isUncd ? t('label1_upload_file') : t('label1.edob.upload.file')}
                                <button className="link"
                                    aria-label={t('open_in_new_window')} onClick={showFileFormatInfo} onKeyPress={keyDownHandler}>
                                    <InfoOutlinedIcon onClick={showFileFormatInfo}></InfoOutlinedIcon>
                                </button>

                                <FileTypeInfoDialog isOpen={fileTypeInfoOpen} handleClose={closeFileTypeInfoOpenDialog} dialogContent={
                                    {
                                        title: t("accepted_file_format_title"),
                                        typeList: t("accepted_file_format_list"),
                                        closeButton: t("button_close")
                                    }
                                }></FileTypeInfoDialog>
                            </Grid>
                            {!ctx.isUncd && <p></p>}
                            <Grid item xs={12} className="row">
                                {ctx.isUncd ? t("label1_upload_file1") : t("label2.edob.upload.file")}
                            </Grid>
                            <Grid item xs={12} className="row">
                                {ctx.isUncd ? t("label1_upload_file2") : t("label3.edob.upload.file")}
                            </Grid>
                            {!ctx.isUncd && <Grid item xs={12} className="row" style={{paddingTop: 0}}>
                                <ul className='edobUploadLinks'>
                                    <li>
                                        <Link href={t("edob.upload.file.link1.href")} underline="hover" target="_blank">
                                        {t("edob.upload.file.link1")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={t("edob.upload.file.link2.href")} underline="hover" target="_blank">
                                        {t("edob.upload.file.link2")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href={t("edob.upload.file.link3.href")} underline="hover" target="_blank">
                                        {t("edob.upload.file.link3")}
                                        </Link>
                                    </li>
                                </ul>
                            </Grid>}
                            {!ctx.isUncd && <Grid item xs={12} className="row">
                                {t("label4.edob.upload.file")}
                            </Grid>}
                            {!ctx.isUncd && <Grid item xs={12} className="row">
                                {t("label5.edob.upload.file")}
                            </Grid>}
                            {!ctx.isUncd && <Grid item xs={12} className="row" style={{ marginBottom: '10px' }}>
                                {t("document-upload-addition-text1")}
                                <Link href={t("document-upload-addition-link-href")} underline="hover" target="_blank">
                                    {t("document-upload-addition-link")}
                                </Link>
                                {t("document-upload-addition-text2")}
                            </Grid>}
                            {!ctx.isUncd && <Grid item xs={12} className="row">
                                {t("label6.edob.upload.file")}
                                <Link href={t("edob.upload.file.link4.href")} underline="hover" target="_blank">
                                    {t("edob.upload.file.link4")}
                                </Link>
                                {t("label7.edob.upload.file")}
                            </Grid>}
                            {ctx.isUncd && <Grid item xs={12} className="row">
                                {t("label1_upload_file3")}
                            </Grid>}
                            {ctx.isUncd && <Grid item xs={12} className="row">
                                {t("label1_upload_file4")}
                            </Grid>}

                            <FileDropzone text={t('drag_and_drop_document')} buttonText={t('drag_and_drop_or_browse2')}
                                ondrop={onFileSelected} onfileDialogOpen={onfileDialogOpen} onfileDialogCancel={onfileDialogCancel} />

                            {ctx.documentUploadQueue.length > 0 && <Grid item xs={12} className="row" >

                                <Typography variant="h2">{t('tell_about_document')}</Typography>

                            </Grid>}

                            {ctx.documentUploadQueue.length > 0 && (ctx.documentUploadQueue.map((item, index) => {
                                return <Grid item xs={12} className="row" key={'filename' + index}>

                                    <Grid container justifyContent="space-between" style={{ width: '95%', backgroundColor: '#FFFFFF', padding: 10, margin: 'auto' }} columns={2} rowSpacing={1}>
                                        <Grid item className="row" xs={1} style={{ minWidth: '108px', wordBreak: 'break-word', whiteSpace: 'break-spaces' }}>

                                            {item._file.name}
                                            {item._file.size / 1024 / 1024 >= 1 && <label style={{ marginLeft: 20 }}>{Math.round(item._file.size / 1024 / 1024)} {t("file_size_mb")}</label>}
                                            {item?._file?.size / 1024 / 1024 < 1 && <label style={{ marginLeft: 20 }}>{Math.round(item._file?.size / 1024)} {t('file_size_kb')}</label>}
                                        </Grid>
                                        <Grid item xs="auto">
                                            <button className="link" aria-label={'file' + (index + 1) + item._file.name + t('button_remove_file')} onClick={(e) => { onRemoveHandler(item, e); }}>{t('button_remove_file')}</button>
                                        </Grid>

                                        <Grid item xs={2} className="row mb-2">

                                            <Typography variant="body1">{t("upload_progress")} {getItemProgress(item)}{t("progress_percent")}</Typography>
                                            <div >
                                                <LinearProgress aria-label={'file' + (index + 1) + " " + item._file.name + " " + t("upload")} variant="determinate" value={getItemProgress(item)} />
                                            </div>

                                        </Grid>
                                        {!ctx.isUncd && <Grid item xs={2} className="row mt-3">

                                            <Typography className="doclable" variant="body1">
                                                <label aria-label={'file' + (index + 1) + item._file.name + t('message_dob_documentation_docfrom')} style={{ marginBottom: '0px' }} id={'formtype' + index}>
                                                    <strong>{t('message_dob_documentation_docfrom')}</strong>
                                                </label>
                                                <button className="link" style={{ marginLeft: '10px', marginTop: '3px', marginRight: '20px' }}
                                                    aria-label={'file' + (index + 1) + item._file.name + t('message_dob_documentation_docfrom') + ' opens in a dialog'} onClick={showIsThisWsib} onKeyPress={keyDownIsThisWsibHandler}>
                                                    <InfoOutlinedIcon onClick={showIsThisWsib}></InfoOutlinedIcon>
                                                </button>
                                            </Typography>
                                            
                                            <IsThisWsibDialog isOpen={isThisWsibOpen} handleClose={closeIsThisWsibDialog} dialogContent={
                                                {
                                                    title: t("label_wsibform_title"),
                                                    wsibTitle: t("label_wsibform_wsibforms"),
                                                    wsibContent: t("label_wsibform_wsibforms_text"),
                                                    nonWsibTitle: t("label_wsibform_nonwsibforms"),
                                                    nonWsibContent: t("label_wsibform_nonwsibforms_text"),
                                                    closeButton: t("button_close")
                                                }
                                            }></IsThisWsibDialog>
                                            <RadioGroup
                                                row
                                                aria-labelledby={'formtype' + index}
                                                name="row-radio-buttons-group"
                                                value={item.isWsibForm}
                                                onChange={(e) => isWsibSwitchHandle(e, item)}
                                            >
                                                <FormControlLabel value='yes' control={<Radio />} label={t("message_dob_documentation_docfromYes")} />
                                                <FormControlLabel value='no' control={<Radio />} label={t("message_dob_documentation_docfromNo")} />
                                            </RadioGroup>
                                        </Grid>}
                                        {!ctx.isUncd && item.isWsibForm === 'yes' && item.isWsibTouched && <Grid item xs={2} className="row mt-3">

                                            <Typography variant="body1"><label aria-label={'file' + (index + 1) + item._file.name + t('message_dob_documentation_doctype')} style={{ marginBottom: '0px' }} id={'doctype' + index} htmlFor={'edobformselection' + index}>
                                                {t('message_dob_documentation_doctype')} 
                                            </label></Typography>

                                        </Grid>}
                                        {!ctx.isUncd && submitClicked &&!item.isWsibTouched && <Grid item xs={12} className="row mb-2">
                                            <Typography className="error" color="error" role="alert" variant="body2" variantMapping={{ body2: 'div' }}>{t("error.message.select.doc.from")}</Typography>
                                        </Grid>}
                                        {!ctx.isUncd && item.isWsibForm === 'yes' && item.isWsibTouched && <Grid item xs={2} className="row">
                                            <FormDropdownList id={'edobformselection' + index} type='wsib forms' defaultValue={getDefaultEdobValue(item)} placeholder={t("label_dob_documentation_doctype")}
                                                options={getWsibFormNameList(i18n.resolvedLanguage, ctx.wsibFormList)} currentItem={item} onChangedForm={(value) => edobFormTypeChanged(value, item)} wisbForms={(value) => switchWsibForm(value)}
                                                edobForms={(value) => switchEdobForm(value)} categories={(value) => switchCategory(value)} resetSubFormsHandler={(value) => resetSubFormsHandler(value)} resetSubForms={resetSubForms} />
                                            { (submitClicked && !item.edobForm) && <FormHelperText id="formselection-helper-text" style={{ marginLeft: '14px', marginTop: '4px', marginBottom: "24px" }} error={true}>{t("error_message_select_doc_type")}</FormHelperText>}

                                        </Grid>}
                                        {!ctx.isUncd && item.isWsibForm === 'no' && item.isWsibTouched && <Grid item xs={2} className="row mt-3">
                                            <Typography className="doclable" variant="body1">
                                                <label aria-label={'file' + (index + 1) + item._file.name + t('message_dob_documentation_catogory')} style={{ marginBottom: '0px' }} id={'catogorytype' + index} htmlFor={'catogoryselection' + index}>
                                                    <strong>{t('message_dob_documentation_catogory')}</strong>
                                                </label>
                                                <button className="link" style={{ marginLeft: '10px', marginTop: '3px', marginRight: '20px' }}
                                                    aria-label={'file' + (index + 1) + item._file.name + t('message_dob_documentation_catogory') + ' opens in a dialog'} onClick={e => showCategory(e, item)} onKeyPress={e => keyDownCategoryHandler(e, item)}>
                                                    <InfoOutlinedIcon onClick={e => showCategory(e, item)}></InfoOutlinedIcon>
                                                </button>
                                            </Typography>
                                            
                                            <IsCategoryDialog isOpen={item.isCategoryOpen} handleClose={e => closeCategoryDialog(e, item)} dialogContent={
                                                {
                                                    title: t("message_dob_documentation_catogory"),
                                                    categoryList: ctx.categoryList,
                                                    lang: i18n.resolvedLanguage,
                                                    closeButton: t("button_close")
                                                }
                                            }></IsCategoryDialog>

                                        </Grid>}
                                        {!ctx.isUncd && item.isWsibForm === 'no' && item.isWsibTouched && <Grid item xs={2} className="row">
                                            <FormDropdownList id={'catogoryselection' + index} type='edob catogory' defaultValue={getDefaultCategoryValue(item)} placeholder={t("label_dob_documentation_doctype")}
                                                options={getCategoriesNameList(i18n.resolvedLanguage, ctx.categoryList)} currentItem={item} onChangedForm={(value) => categoryChanged(value, item)} wisbForms={(value) => switchWsibForm(value)}
                                                edobForms={(value) => getEdobForm(value)} categories={(value) => switchCategory(value)} resetSubFormsHandler={(value) => resetSubFormsHandler(value)} resetSubForms={resetSubForms} />
                                            { (submitClicked && !item.category) && <FormHelperText id="formselection-helper-text" style={{ marginLeft: '14px', marginTop: '4px', marginBottom: "24px" }} error={true}>{t("error.message.select.doc.category")}</FormHelperText>}

                                        </Grid>}
                                        {!ctx.isUncd && item.isWsibForm === 'no' && item.category?.categoryId && <Grid item xs={2} className="row mt-3">
                                            <Typography className="doclable" variant="body1">
                                                <label aria-label={'file' + (index + 1) + item._file.name + t('message_dob_documentation_doctype')} style={{ marginBottom: '0px' }} id={'subdoctype' + index} htmlFor={'subformselection' + index}>
                                                    <strong>{t('message_dob_documentation_doctype')}</strong>
                                                </label>
                                                <button className="link" style={{ marginLeft: '10px', marginTop: '3px', marginRight: '20px' }}
                                                    aria-label={'file' + (index + 1) + item._file.name + t('message_dob_documentation_doctype') + ' opens in a dialog'} onClick={e => showSubDocument(e, item)} onKeyPress={e => keyDownSubDocumentHandler(e, item)}>
                                                    <InfoOutlinedIcon onClick={e => showSubDocument(e, item)}></InfoOutlinedIcon>
                                                </button>
                                            </Typography>
                                            
                                            <IsSubDocumentDialog isOpen={item.isSubDocumentOpen} handleClose={e => closeSubDocumentDialog(e, item)} dialogContent={
                                                {
                                                    title: t("message_dob_documentation_doctype"),
                                                    formList: item.noWsibFormList,
                                                    lang: i18n.resolvedLanguage,
                                                    closeButton: t("button_close")
                                                }
                                            }></IsSubDocumentDialog>

                                        </Grid>}
                                        {!ctx.isUncd && item.isWsibForm === 'no' && item.category?.categoryId && <Grid item xs={2} className="row">
                                            <SubFormDropdownList id={'subformselection' + index} type='edob subform' defaultValue={getDefaultSubformsValue(item)} placeholder={t("label_dob_documentation_doctype")}
                                                options={getNoWsibFormNameList(i18n.resolvedLanguage, item.noWsibFormList)} currentItem={item} onChangedForm={(value) => subFormChanged(value, item)}
                                                subForms={(value) => switchSubForm(value, item)} resetSubFormsHandler={(value) => resetSubFormsHandler(value)} resetSubForms={resetSubForms} />
                                            { (submitClicked && !item.subEdobForm) && <FormHelperText id="formselection-helper-text" style={{ marginLeft: '14px', marginTop: '4px', marginBottom: "24px" }} error={true}>{t("error.message.select.doc.which")}</FormHelperText>}

                                        </Grid>}
                                        {ctx.isUncd && <Grid item xs={2} className="row mt-3">

                                            <Typography variant="body1"><label aria-label={'file' + (index + 1) + item._file.name + t('message_dob_documentation_doctype')} style={{ marginBottom: '0px' }} id={'doctype' + index} htmlFor={'uncdformselection' + index} >
                                                {t('message_dob_documentation_doctype')} 
                                            </label></Typography>

                                        </Grid>}
                                        {ctx.isUncd && <Grid item xs={2} className="row">
                                            <FormDropdownList id={'uncdformselection' + index} type='wsib forms' defaultValue={getDefaultValue(item)} placeholder={t("label_dob_documentation_doctype")} options={getMainFormNameList(i18n.resolvedLanguage, ctx.formList)} currentItem={item}
                                                onChangedForm={(value) => formTypeChanged(value, item)} wisbForms={(value) => switchWsibForm(value)}
                                                edobForms={(value) => switchEdobForm(value)} categories={(value) => switchCategory(value)} resetSubFormsHandler={(value) => resetSubFormsHandler(value)} resetSubForms={resetSubForms} />
                                            { (submitClicked && !item.wsibForm) && <FormHelperText id="formselection-helper-text" style={{ marginLeft: '14px', marginTop: '4px', marginBottom: "24px" }} error={true}>{t("error_message_select_doc_type")}</FormHelperText>}
                                        </Grid>}
                                    </Grid>

                                </Grid>
                            }))}

                            <UploadInProgressDialog isOpen={uploadInProgressOpen} handleClose={closeUploadInProgressDialog} dialogContent={
                                {
                                    title: t("upload_in_progress_title"),
                                    content: t("upload_in_progress_content"),
                                    closeButton: t("button_close")
                                }
                            }></UploadInProgressDialog>
                            {ctx.showAlertMsg && (
                                <Grid item xs={12} className="row" style={{ margin: 'auto', padding: '10px' }}>
                                    <div className="warningContainer">
                                        <span className="warningIcon">
                                            <figure className="imageFigure"><img src="/assets/b2c/images/Alert.svg" alt="warning icon" className="infoIcon" id="Image" /></figure>
                                        </span>
                                        <span id="acnDescriptionSpan" className="warningText">
                                            {t("document-upload-addition-text1")}
                                            <Link href={t("document-upload-addition-link-href")} underline="hover" target="_blank">
                                                {t("document-upload-addition-link")}
                                            </Link>
                                            {t("document-upload-addition-text2")}
                                        </span>
                                    </div>
                                </Grid>
                            )}
                            <Grid item xs={12} >
                                <br role="presentation"/>
                                <br role="presentation"/>
                                <span aria-hidden="true"></span><button className="navbtn" onClick={props.onPrev} aria-label={t("button.previous") + t("gotoLabel") + t("step2Label")}>{t("button.previous")}</button><button className="btn-wsib" type="submit" onClick={onNext} aria-label={t("label.next") + t("gotoLabel") + t("step4Label")}>{t("label.next")}</button> &nbsp;<span aria-hidden="true"></span>
                            </Grid>

                        </React.Fragment>
                    }
                </Grid>
            </Container>
    );
}

export default DocUpload;